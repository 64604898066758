import React, {useEffect, useState} from "react";
import Input from "../Form/Input";
import Button from "../Form/Button";
import SendIcon from '@material-ui/icons/Send';

export default props => {
    const {create, edit, disabled, editPost} = props;
    const [message, setMessage] = useState("");
    const [isEdit, setEdit] = useState(false);
    useEffect(
        () => {
            if(editPost){
                setEdit(editPost);
                setMessage(editPost.text);
            }else{
                setEdit(false);
            }
        },
        [editPost]
    );
    return (
        <div className={"create-post"}>
            <Input
                disabled={!!disabled}
                variant={"outlined"}
                multiline
                labelText="Nachricht"
                value={message}
                id="message"
                formControlProps={{
                    fullWidth: true
                }}
                handleChange={e => setMessage(e.target.value)}
                type="text"
            />
            <Button
                disabled={!message.replace(/\s/g, '').length || !!disabled}
                color={"primary"}
                variant={"contained"}
                onClick={
                    () => {
                        if(isEdit){
                            edit(isEdit.id, message);
                        }else{
                            create(message);
                        }
                        setMessage("");
                    }
                }
            >
                <div className={"send-button"}>
                    <SendIcon size={"large"} />
                    <span>Senden</span>
                </div>
            </Button>
        </div>
    );
}
