import {useCallback, useEffect, useState} from "react";
import fire from "../modules/firebase";
import differenceInMinutes from 'date-fns/differenceInMinutes';

export const useAuthObserver = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [error, setError] = useState(false);
    const updateUserData = useCallback(
        (fields) => {
            setUser(oldData => ({
                ...oldData,
                data: {
                    ...oldData.data,
                    ...fields
                }
            }));
        },
        []
    );
    useEffect(
        () => {
            const unsub = fire.auth().onAuthStateChanged(function(user) {
                if (user) {
                    setUser(user);
                    fire.firestore().collection("guests").doc(user.uid).get().then(
                        doc => {
                            const data = doc.data();
                            if(
                                !doc.exists
                                // After 10 min creation -> Prevent long Signup Process Bug
                                && differenceInMinutes(new Date(), new Date(fire.auth().currentUser.metadata.creationTime)) > 10
                            ){
                                // Delete User that is not a guest
                                fire.auth().currentUser.delete();
                            }else{
                                updateUserData(data);
                                setLoading(false);
                            }
                        }
                    ).catch(
                        e => {
                            setError(e);
                            setLoading(false);
                        }
                    );
                    // Login
                    console.log("LOGGED IN");
                } else {
                    setUser({});
                    setLoading(false);
                    // Logout
                    console.log("LOGGED OUT");
                }
            });
            return () => unsub();
        },
        []
    );
    return [loading, user, error, setUser];
};

export const useGuestData = (user) => {
      if(user.data && user.data.isGuest){
          return user.data;
      }else{
          return {};
      }
};

export const useHostData = (user, isLoading, load) => {
    const [guests, setGuests] = useState({});
    const [error, setError] = useState(false);
    useEffect(
        () => {
            if(!isLoading && user.data && user.data.isAdmin){
                load.setLoading(true);
                fire.firestore().collection("guests").get().then(snapshot => {
                    if (snapshot.docs.length <= 0) {
                        // No guests yet
                        load.setLoading(false);
                    } else {
                        snapshot.docs.map(doc => {
                            const data = doc.data();
                            if (data && data.isGuest) {
                                setGuests(ol => ({
                                    ...ol,
                                    [doc.id]: {
                                        name: data.name,
                                        email: data.email,
                                        numberGuests: data.numberGuests,
                                        guests: data.guests,
                                        attends: data.attends,
                                        guestUpdateTime: data.guestUpdateTime,
                                        totalNumber: data.attends ? 0 : data.numberGuests + 1,
                                        foodOption: data.foodOption ? parseInt(data.foodOption) : 0,
                                        customText: data.customText ? data.customText : ""
                                    }
                                }))
                            }
                        });
                        load.setLoading(false);
                    }
                }).catch(
                    e => {
                        setError(e);
                        load.setLoading(false);
                    }
                )
            }
        },
        [user, isLoading]
    );
    return [guests, setGuests, error];
};
