import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import ImageIcon from '@material-ui/icons/Image';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "../../Dialogs/Transition";
import {useDropzone} from 'react-dropzone';
import ProgressBar from "../../Loading/ProgressBar";
import {editPages, uploadImage} from "../../../actions/pages";
import Button from "../../Form/Button";
import Select from "../../Form/Select";
import {styles, xtraContentTypes} from "./constants";
import ImageComponent from "../Image";
import "./dialog.css";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import Slider from "@material-ui/core/Slider";
import Input from "../../Form/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";



export default function EditImageDialog(props) {
    const {open, close, item, pages, setPages} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(false);
    const [step, setStep] = useState(0);
    // Image style
    const [style, setStyle] = useState({
        type: styles[0].value,
        height: 90
    });
    // Extra Content
    const [xtraContent, setXtraContent] = useState([]);
    useEffect(
        () => {
            if(item && item.style){
                setStyle(item.style);
            }else if(item){
                setStyle({
                    type: styles[0].value,
                    height: 90
                })
            }
        },
        [item]
    );
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/jpeg, image/png',
        maxSize: 10000000,
        multiple: false,
        noDrag: false,
        noDragEventsBubbling: true,
        onDropRejected: (files, event) => {
            setError("Beim Hochladen ist etwas schief gelaufen. Überprüfe ob die Dateien den Richtlinien entsprechen.");
        },
        onDrop: async acceptedFiles => {
            try{
                const result = await uploadImage(acceptedFiles[0], item.id, setProgress);
                const form = {
                    id: item.id,
                    type: "image",
                    image: result,
                    style: style,
                    xtraContent: xtraContent
                };
                const index = pages.findIndex(i => i.id === item.id);
                if(index > -1){
                    // Edit
                    pages[index] = form;
                }else{
                    // New
                    pages.push(form);
                }
                const newPages = await editPages(pages, item.pageType);
                setPages(newPages);
                setProgress(0);
                setStep(0);
                close();
            }catch (e) {
                setError(typeof e === 'string' ? e : e.message);
            }
        }
    });
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                setProgress(0);
                setStep(0);
                setXtraContent([]);
                close();
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullScreen={fullScreen}
            maxWidth={"xs"}
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title">Füge Bild hinzu</DialogTitle>
            <DialogContent>
                {
                    step === 0 && (
                        (item.image && item.image.url) && (
                            <div className={"dialog-image-preview"}>
                                <ImageComponent
                                    preview
                                    key={item.id}
                                    index={1}
                                    item={{...item, style: {
                                            ...style,
                                            height: style.height * 3
                                        },
                                        xtraContent: xtraContent
                                    }}
                                    style={{
                                        height: 200,
                                        width: "unset",
                                        marginTop: style.type === "fullscreen" ? 0 : 10,
                                        marginBottom: style.type === "fullscreen" ? 0 : 10
                                    }}
                                    containerStyle={{
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                />
                            </div>
                        )
                    )
                }
                {
                    step === 0 ? (
                        <DialogContentText id="alert-dialog-slide-description">
                            <Select
                                labelText={"Bild Stil"}
                                formControlProps={{
                                    fullWidth: true,
                                    style: {marginTop: 20}
                                }}
                                handleChange={e => {
                                    setStyle(o => ({...o, type: e.target.value}));
                                }}
                                value={style.type}
                                options={styles}
                            />
                            <div style={{marginTop: 20}}>
                                <label style={{fontSize: "0.75em"}}>
                                    Höhe
                                </label>
                                <Slider
                                    value={style.height}
                                    min={0}
                                    max={100}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    // classes={{ root: classes.slider }}
                                    onChange={(e, height) => setStyle(o => ({...o, height: height}))}
                                />
                            </div>
                            <Button
                                onClick={() => setStep(1)}
                                color="primary"
                                round
                                variant={"outlined"}
                                style={{marginTop: 20, display: "block"}}
                            >
                                Foto Hochladen
                            </Button>
                            {
                                // Extra Content for fullscreen image
                                style.type === "fullscreen" && (
                                    <div>
                                        {
                                            xtraContent.map(
                                                (item, index) => (
                                                    <div key={index} className={"panel secondary bordered stretch-vertical"}>
                                                        <Input
                                                            multiline
                                                            value={item.text}
                                                            labelText={"Text"}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            handleChange={(e)=>{
                                                                let array = [...xtraContent];
                                                                array[index] = {
                                                                    ...array[index],
                                                                    text: e.target.value
                                                                };
                                                                console.log(array);
                                                                setXtraContent(array);
                                                            }}
                                                        />
                                                        <div className={"align-horizontal"} style={{alignItems: "flex-end"}}>
                                                            <Select
                                                                labelText={"Text Stil"}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    style: {marginTop: 20}
                                                                }}
                                                                handleChange={e => {
                                                                    let array = [...xtraContent];
                                                                    array[index] = {
                                                                        ...array[index],
                                                                        type: e.target.value
                                                                    };
                                                                    setXtraContent(array);
                                                                }}
                                                                value={item.type}
                                                                options={xtraContentTypes}
                                                            />
                                                            <div
                                                                className={"simple-color-switch"}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginRight: 10,
                                                                    backgroundColor: item.color === "secondary" ? "var(--secondaryText)" : "var(--text)"
                                                                }}
                                                                onClick={() => {
                                                                    let array = [...xtraContent];
                                                                    array[index] = {
                                                                        ...array[index],
                                                                        color: item.color === "text" ? "secondary" : "text"
                                                                    };
                                                                    setXtraContent(array);
                                                                }}
                                                            />
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={
                                                                    () => {
                                                                        let array = [...xtraContent];
                                                                        array.splice(index, 1);
                                                                        setXtraContent(array);
                                                                    }
                                                                }
                                                            >
                                                                <DeleteIcon color={"error"}/>
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        }
                                        <Button
                                            onClick={() => setXtraContent(o => [...o, {text: "", type: xtraContentTypes[0].value, color: "secondary"}])}
                                            color="secondary"
                                            round
                                            variant={"outlined"}
                                            style={{marginTop: 20, display: "block"}}
                                        >
                                            Text Hinzufügen
                                        </Button>
                                    </div>
                                )
                            }
                        </DialogContentText>
                    ) : (
                        <>
                            <DialogContentText id="alert-dialog-slide-description">
                                Wähle ein Bild aus, welches du hochladen möchtest.
                            </DialogContentText>
                            {
                                error && (
                                    <span className={"error-text"}>
                                        {error}
                                    </span>
                                )
                            }
                            {
                                progress > 0 ? (
                                    <ProgressBar progress={progress} style={{marginBottom: 17}}/>
                                ) : (
                                    <div {...getRootProps({className: "image-dropzone"})}>
                                        <input {...getInputProps()} />
                                        <div />
                                        <ImageIcon fontSize={"large"} color={"primary"}/>
                                        <span>
                                            Max. Größe: 9MB <br />
                                            Unterstützte Dateitypen: .jpg, .jpeg, .png
                                        </span>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setProgress(0);
                        setStep(0);
                        setXtraContent([]);
                        close();
                    }}
                    color="info"
                >
                    Abbrechen
                </Button>
                <Button
                    onClick={async () => {
                        const form = {
                            ...item,
                            style: style,
                            xtraContent: xtraContent
                        };
                        const index = pages.findIndex(i => i.id === item.id);
                        if(index > -1){
                            // Edit
                            pages[index] = form;
                            const newPages = await editPages(pages, item.pageType);
                            setPages(newPages);
                            setProgress(0);
                            setStep(0);
                            close();
                        }
                    }}
                    disabled={step === 0 && (!item.image || !item.image.url)}
                    color="primary"
                >
                    {pages.findIndex(i => i.id === item.id) > -1 ? "Speichern" : "Hinzufügen"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
