import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "../../Dialogs/Transition";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Input from "../../Form/Input";
import {editPages, uploadImage} from "../../../actions/pages";
import {waitForEach} from "../../../modules/functions";

const emptyItem = {
    title: "",
    text: "",
    address: "",
    website: "",
    telephone: ""
};

const createGoogleImages = async (form) => {
    let items = form.rooms.items;
    await waitForEach(
        items,
        async (item, index) => {
            try {
                const address = item.address.replace(/[ \n]+/g, "+");
                const img = await fetch(`https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=15&size=500x500&maptype=roadmap&markers=color:red%7C${address}&key=AIzaSyD1XikGtkeUfuHvY5ctXuBv5OvI9aGfe94&map_id=fc44647e89937eae`);
                const res = await fetch(img.url);
                const file = await res.blob();
                const image = await uploadImage(file, form.id + "/location" + index, () => null);
                items[index] = {
                    ...item,
                    image: image
                };
            }catch (e) {
                console.error(e);
            }
        }
    );
    return ({
        ...form,
        rooms: {
            ...form.rooms,
            items: items
        }
    });
};

export default function EditRoomsDialog(props) {
    const {open, callback, close, pageType, pages, setPages, item, setLoading} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState({
        ...item,
        rooms: {
            items: [],
            title: "Unterkünfte",
            text: "Wir wollen euch hier über mögliche Unterkünfte informieren."
        }
    });
    useEffect(
        () => {
            setForm(o => ({
                ...o,
                ...item
            }))
        },
        [item]
    );
    const [message, setMessage] = useState(props.message ? props.message : null);
    const [error, setError] = useState({});
    const save = useCallback(
        async (form) => {
            try{
                setLoading(true);
                const index = pages.findIndex(i => i.id === form.id);
                const {pageType, ...r} = form;
                const newItem = await createGoogleImages({
                    ...r,
                    type: "rooms"
                });
                if(index > -1){
                    // Edit
                    pages[index] = newItem;
                }else{
                    // New
                    pages.push(newItem);
                }
                const newPages = await editPages(pages, pageType);
                setPages(newPages);
                setLoading(false);
                close();
            }catch (e) {
                console.log(e);
                setLoading(false);
                if(e.info){
                    setMessage(e.info);
                }else{
                    setError(typeof e === 'string' ? e : e.message);
                }
            }
        },
        [pages, pageType]
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title">Unterkünfte hinzufügen</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <form>
                        {
                            message && (<div className={"panel"}>{message}</div>)
                        }
                        <Input
                            multiline
                            value={form.rooms.title}
                            labelText={"Seitentitel"}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            handleChange={(e)=>{
                                setForm(o => ({
                                    ...o,
                                    rooms: {
                                        ...o.rooms,
                                        title: e.target.value
                                    }
                                }));
                            }}
                            error={error.pageTitle}
                        />
                        <Input
                            multiline
                            value={form.rooms.text}
                            labelText={"Seitenbeschreibung"}
                            formControlProps={{
                                fullWidth: true,
                                style: {
                                    marginTop:  10
                                }
                            }}
                            handleChange={(e)=>{
                                setForm(o => ({
                                    ...o,
                                    rooms: {
                                        ...o.rooms,
                                        text: e.target.value
                                    }
                                }));
                            }}
                            error={error.text}
                        />
                        <div style={{marginTop: 20}}>
                            Füge eine oder mehrere Unterkünfte hinzu
                        </div>
                        {
                            form.rooms.items.map((item, index) => (
                                <div key={index} className={"simpleBorderArea align-vertical"}>
                                    <Input
                                        value={item.title}
                                        labelText={"Unterkunftsname"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        handleChange={(e)=>{
                                            let array = form.rooms.items;
                                            array[index] = {
                                                ...array[index],
                                                title: e.target.value
                                            };
                                            setForm(o => ({
                                                ...o,
                                                rooms: {
                                                    ...o.rooms,
                                                    items: array
                                                }
                                            }));
                                        }}
                                        error={error.title && error.title[index]}
                                    />
                                    <Input
                                        multiline
                                        value={item.text}
                                        labelText={"Freie Beschreibung"}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {
                                                marginTop: 10
                                            }
                                        }}
                                        handleChange={(e)=>{
                                            let array = form.rooms.items;
                                            array[index] = {
                                                ...array[index],
                                                text: e.target.value
                                            };
                                            setForm(o => ({
                                                ...o,
                                                rooms: {
                                                    ...o.rooms,
                                                    items: array
                                                }
                                            }));
                                        }}
                                        error={error.text && error.text[index]}
                                    />
                                    <Input
                                        value={item.address}
                                        labelText={"Addresse"}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {
                                                marginTop: 10
                                            }
                                        }}
                                        handleChange={(e)=>{
                                            let array = form.rooms.items;
                                            array[index] = {
                                                ...array[index],
                                                address: e.target.value
                                            };
                                            setForm(o => ({
                                                ...o,
                                                rooms: {
                                                    ...o.rooms,
                                                    items: array
                                                }
                                            }));
                                        }}
                                        error={error.address && error.address[index]}
                                    />
                                    <Input
                                        value={item.website}
                                        labelText={"Webseite"}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {
                                                marginTop: 10
                                            }
                                        }}
                                        handleChange={(e)=>{
                                            let array = form.rooms.items;
                                            array[index] = {
                                                ...array[index],
                                                website: e.target.value
                                            };
                                            setForm(o => ({
                                                ...o,
                                                rooms: {
                                                    ...o.rooms,
                                                    items: array
                                                }
                                            }));
                                        }}
                                        error={error.website && error.website[index]}
                                    />
                                    <Input
                                        value={item.telephone}
                                        labelText={"Telefon"}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {
                                                marginTop: 10
                                            }
                                        }}
                                        handleChange={(e)=>{
                                            let array = form.rooms.items;
                                            array[index] = {
                                                ...array[index],
                                                telephone: e.target.value
                                            };
                                            setForm(o => ({
                                                ...o,
                                                rooms: {
                                                    ...o.rooms,
                                                    items: array
                                                }
                                            }));
                                        }}
                                        error={error.telephone && error.telephone[index]}
                                    />
                                    <div style={{alignSelf: "flex-end", marginTop: 10}}>
                                        <Button
                                            aria-label="delete"
                                            onClick={
                                                () => {
                                                    let array = form.rooms.items;
                                                    array.splice(index, 1);
                                                    setForm(o => ({
                                                        ...o,
                                                        rooms: {
                                                            ...o.rooms,
                                                            items: array
                                                        }
                                                    }));
                                                }
                                            }
                                            color="error"
                                        >
                                            Löschen
                                        </Button>
                                    </div>

                                </div>
                            ))
                        }
                        <Button
                            onClick={() => setForm(o => (
                                {
                                    ...o,
                                    rooms: {
                                        ...o.rooms,
                                        items: [...o.rooms.items, emptyItem]
                                    }
                                }
                            ))}
                            color="secondary" variant={"outlined"} style={{marginTop: 20}}
                        >
                            Unterkünfte hinzufügen
                        </Button>
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="info">
                    Abbrechen
                </Button>
                <Button
                    onClick={async () => {
                        await save(form);
                        if(callback){
                            callback();
                        }
                    }}
                    color="primary"
                >
                    {pages.findIndex(i => i.id === form.id) > -1 ? "Speichern" : "Hinzufügen"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
