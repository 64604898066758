import React from 'react';
import "./pages.css";
import ArrowDown from "../Icons/ArrowDown";


/*
    props: list (array of JSX elements for each page), array (flag if arrow for scrolling is shown), last (flag if last page also has arrow)
 */
export default props => {
    const {list, arrow, last} = props;
    const getNewScrollPosition = () => (
        Math.floor(window.scrollY / (window.innerHeight)) * (window.innerHeight) + window.innerHeight
    );
    return(
        <>
            {
                list.map(
                    (item, index) => (
                        <div key={index} className={"full-height-page"} style={{
                            backgroundColor: index % 2 ? "var(--backgroundPrimaryLight)" : "var(--background)"
                        }}>
                            {item}
                            {
                                arrow && (last || index < list.length -1) && (
                                    <div className={"scroll-arrow"} onClick={() => window.scrollTo({
                                        top: getNewScrollPosition(), behavior: 'smooth'
                                    })}>
                                        <ArrowDown width={28} height={14} fill={"var(--dark-grey)"}/>
                                    </div>
                                )
                            }
                        </div>
                    )
                )
            }
        </>
    );
};
