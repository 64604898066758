import React, {useCallback, useEffect, useState} from "react";
import {useMessages} from "../../hooks/messages";
import "./index.css";
import GroupItem from "./GroupItem";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddMessageGroupDialog from "../Dialogs/AddMessageGroup";
import CreatePost from "./CreatePost";
import MessageStream from "./MessageStream";
import fire from "../../modules/firebase";
import DeleteDialog from "../Dialogs/Delete";

export default props => {
    const {user, setLoading} = props;
    const [group, setGroup] = useState();
    const [groupDialog, setGroupDialog] = useState(false);
    // Create  & memo userData
    const setUD = useCallback(
        (user) => {
            const uD = {uid: user.uid, name: user.data ? user.data.name : ""};
            if(user.data && user.data.isAdmin){
                uD.isAdmin = true;
            }
            if(user.data && user.data.isGuest){
                uD.isGuest = true;
            }
            return uD;
        },
        []
    );
    const [userData, setUserData] = useState(setUD(user));
    useEffect(
        () => {
            setUserData(setUD(user));
        },
        [user]
    );
    // Get Messages API
    const [
        messages,
        addMessage,
        editMessage,
        deleteMessage,
        addGroup,
        editGroup,
        deleteGroup
    ] = useMessages(setLoading, userData);
    const posts = group && messages[group] ? messages[group].messages : {};
    const groupIDs = Object.keys(messages);
    useEffect(
        () => {
            if(!group){
                setGroup(groupIDs[0]);
            }
        },
        [groupIDs]
    );
    // Edit Post
    const [editPost, setEditPost] = useState(false);
    // Delete & Delete Group
    const [isDeleteGroup, setDeleteGroup] = useState(false);
    const [isEditGroup, setEditGroup] = useState(false);
    return (
        <div className={"message-page"}>
            {
                // Dialogs
            }
            <AddMessageGroupDialog
                open={!!groupDialog}
                close={() => setGroupDialog(false)}
                submit={form => {
                    addGroup(form);
                    setGroupDialog(false);
                }}
                item={groupDialog}
            />
            <AddMessageGroupDialog
                edit
                open={!!isEditGroup}
                close={() => setEditGroup(false)}
                submit={form => {
                    editGroup(isEditGroup, form);
                    setEditGroup(false);
                }}
                item={isEditGroup && messages[isEditGroup]}
            />
            <DeleteDialog
                open={!!isDeleteGroup}
                fail={() => setDeleteGroup(false)}
                success={async () => {
                    try {
                        const result = await deleteGroup(isDeleteGroup);
                        setDeleteGroup(false);
                        setGroup(groupIDs[0]);
                    } catch (e) {
                        console.error(e.message);
                    }
                }}
                item={(isDeleteGroup && messages[isDeleteGroup]) && (
                    <>
                        <div style={{width: "fit-content"}}>
                            <GroupItem
                                active
                                isOwner={false}
                                name={messages[isDeleteGroup].name}
                                onlyGuests={messages[isDeleteGroup].onlyGuests}
                                onClick={() => null}
                            />
                        </div>
                        <div style={{marginTop: 10, color: "var(--error)"}}>
                            Alle enthaltenen Nachrichten werden ebenfalls gelöscht!
                        </div>
                    </>
                )}
            />
            {
                // Rendered Items
            }
            <div className={"group-bar"}>
                <label style={{marginLeft: 10}}>Gruppen</label>
                {
                    groupIDs.map(
                        id => (
                            <GroupItem
                                key={id}
                                name={messages[id].name}
                                onlyGuests={messages[id].onlyGuests}
                                active={id === group}
                                onClick={() => setGroup(id)}
                                isOwner={userData && (userData.isAdmin || messages[id].user.uid === fire.auth().currentUser.uid)}
                                setEdit={() => setEditGroup(id)}
                                setDelete={() => setDeleteGroup(id)}
                            />
                        )
                    )
                }
                <IconButton
                    aria-label="delete"
                    onClick={() => setGroupDialog(true)}
                    size={"small"}
                    color={"primary"}
                >
                    <AddIcon  />
                </IconButton>
            </div>
            <MessageStream
                user={userData}
                messages={group && messages[group] ? messages[group].messages : {}}
                group={group}
                deleteMessage={deleteMessage}
                setEditPost={setEditPost}
            />
            <CreatePost
                disabled={!group}
                create={(message) => addMessage(group, message)}
                edit={(id, message) => {
                    editMessage(id, group, message);
                    setEditPost(false);
                }}
                editPost={editPost}
            />
        </div>
    );
}
