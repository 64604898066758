import React from "react";
import classNames from "classnames";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';

export default props => {
    const {active, name, onClick} = props;
    const classes = classNames({
        ["group-item"]: true,
        active
    });
    return(
        <div className={classes} onClick={onClick}>
            <PhotoAlbumIcon style={{marginRight: 10}}/>
            {name}
        </div>
    );
}
