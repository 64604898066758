import { format} from 'date-fns';
import { de } from 'date-fns/locale';
import formatRelative from 'date-fns/formatRelative';

// returns the right format of a Date.
//      Date can be firebase timestamp, JS Date, or moment-object
//      "format" is target format, e.g. "PP" or "PPP"
// EXAMPLE: safeFormat( TIMESTAMP, "PPP")
export const safeFormat = (date, formatType) => {
    const d = createDate(date);
    return format(d, formatType, { locale: de });
};

export const safeRelative = (date) => {
    const d = createDate(date);
    return formatRelative(d, new Date(), { locale: de });
};

// get Seconds (to compare for sorting) of any date format
export const getSeconds = date => {
    if(date.toDate){
        return date.toDate().getTime() / 1000
    } else if(date.getTime){
        return date.getTime() / 1000;
    } else if(date.seconds){
        return date.seconds;
    } else if(!isNaN(date)){
        // is Number
        if(("" + date).length > 10){
            return date / 1000;
        }else{
            return date;
        }
    }
    return undefined;
};

export const createDate = d => {
    if(d instanceof Date){
        return d;
    }else if(d.toDate){
        return d.toDate();
    }else if(d.seconds) {
        return new Date(d.seconds * 1000);
    }else{
        return new Date(getSeconds(d) * 1000);
    }
};
