import React from "react";
import Pages from "../../components/Layout/Pages";
import {useHistory} from "react-router-dom";
import {createPagesArray} from "../../modules/pages";

export default function Home(props){
    const history = useHistory();
    const {pages} = props;
    const pagesArray = createPagesArray(pages, {setLoading: props.setLoading}, props.settings);
    return(
        <Pages
            list={pagesArray}
        />
    )

}
