import React, {useEffect, useRef, useState} from "react";
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInDays from 'date-fns/differenceInDays';
import {createDate} from "../../../modules/time";
import {useOnScrollIntoComponent} from "../../../hooks/animation";
import Counter from "./Counter";


export default props => {
    const {item} = props;
    const componentRef = useRef();
    const [visible, setVisible] = useState(false);
    const date = createDate(item.countdown.date);
    const now = new Date();
    const [values, setValues] = useState({
        days: Math.max(differenceInDays(date, now), 0),
        hours: Math.max(differenceInHours(date, now) % 24, 0),
        minutes: Math.max(differenceInMinutes(date, now) % 60, 0)
    });
    useEffect(
        () => {
            setInterval(
                () => {
                    const now = new Date();
                    setValues({
                        days: Math.max(differenceInDays(date, now), 0),
                        hours: Math.max(differenceInHours(date, now) % 24, 0),
                        minutes: Math.max(differenceInMinutes(date, now) % 60, 0)
                    })
                },
                60000
            )

        },
        []
    );
    useOnScrollIntoComponent(componentRef, () => setVisible(true), () => null, 100, 100);
    return (
        <div className={"center-text content-box"} ref={componentRef}>
            <h2>{item.countdown.title}</h2>
            {
                // Voller Countdown
                item.countdown.countHoursMinutes ? (
                    <div className={"space-horizontal"}>
                        <div
                            className={"indicator"}
                            style={{flex: 1, textAlign: "center", paddingTop: 10, paddingBottom: 10, marginRight: 20}}
                        >
                            <span className={"indicator-number"}>
                                <Counter from={2 * values.days} to={values.days} go={visible} />
                            </span>
                            <span className={"indicator-label handwritten"}>
                                Tage
                            </span>
                        </div>
                        <div
                            className={"indicator"}
                            style={{flex: 1, textAlign: "center", paddingTop: 10, paddingBottom: 10, marginRight: 20}}
                        >
                            <span className={"indicator-number"}>
                                <Counter from={24} to={values.hours} go={visible} />
                            </span>
                            <span className={"indicator-label handwritten"}>
                                Stunden
                            </span>
                        </div>
                        <div
                            className={"indicator"}
                            style={{flex: 1, textAlign: "center", paddingTop: 10, paddingBottom: 10, marginRight: 20}}
                        >
                            <span className={"indicator-number"}>
                                <Counter from={60} to={values.minutes} go={visible} />
                            </span>
                            <span className={"indicator-label handwritten"}>
                                Minuten
                            </span>
                        </div>
                    </div>
                ) : (
                    // Nur Tage
                    <div
                        className={"indicator"}
                        // style={{flex: 1, textAlign: "center", paddingTop: 10, paddingBottom: 10, marginRight: 20}}
                    >
                        <span className={"indicator-label"} style={{fontSize: "1.5em"}}>
                            Nur noch
                            <span className={"indicator-number"} style={{margin: "0 5px"}}>
                                {values.days}
                            </span>
                            Tage...
                        </span>
                    </div>
                )
            }

        </div>
    )
}
