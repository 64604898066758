import React, {useEffect, useRef, useState} from "react";
import "./pagePreview.css";
import ContentPreview, {ContentPreviewInnerContent} from "./ContentPreview";
import AddIcon from '@material-ui/icons/Add';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {PAGE_TYPES} from "../../modules/pages";
import {usePositionReorder} from "../../hooks/animation";
import Button from "../../components/Form/Button";
import {editPages} from "../../actions/pages";
import DeleteDialog from "../../components/Dialogs/Delete";
import {useHistory} from "react-router-dom";
import fire from "../../modules/firebase";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export default function PagePreview(props){
    const {
        items, setItems, label, createNew, pageType, setLoading,
        editImage, editSignup, editLocations, editCountdown, editTimeline, editRooms, editPersonIntro
    } = props;
    const history = useHistory();
    const [order, updatePosition, updateOrder, reset] = usePositionReorder(items);
    const AddElementRef = useRef();
    const [openMenu, setOpenMenu] = useState(false);
    // delete Page Dialog
    const [deletePage, setDeletePage] = useState(false);
    // Check if Order has changed
    const [orderChanged, setOrderChange] = useState(false);
    useEffect(
        () => {
            let count = order.length;
            if(order.length !== items.length){
                reset();
            }else{
                order.forEach((i, index) => {
                    if(i.id !== items[index].id){
                        setOrderChange(true);
                    }else{
                        count--;
                        if(count === 0){
                            setOrderChange(false);
                        }
                    }
                });
            }

        },
        [order, items]
    );
    return(
        <div className={"center-vertical"} style={{position: "relative"}}>
            <DeleteDialog
                open={!!deletePage}
                fail={() => setDeletePage(false)}
                success={async () => {
                    const newItems = items.filter(item => item.id !== deletePage.id);
                    try {
                        setLoading(true);
                        let result;
                        if(deletePage.type === "image"){
                            result = await editPages(newItems, pageType, deletePage.image);
                        }else if(deletePage.type === "html" || deletePage.type === "locations" || deletePage.type === "rooms" || deletePage.type === "personIntro"){
                            await fire.functions().httpsCallable("deletePageAndImages")({pageType: pageType, pageID: deletePage.id});
                            result = newItems;
                        }else{
                            result = await editPages(newItems, pageType);
                        }
                        setItems(result);
                        setLoading(false);
                        setDeletePage(false)
                    } catch (e) {
                        console.error(e.message);
                        setLoading(false);
                    }
                }}
                item={deletePage && (
                    <div style={{textAlign: "center"}}>
                        <ContentPreviewInnerContent
                            item={deletePage}
                            edit={false}
                            index={1}
                        />
                    </div>
                )}
            />
            <h3>
                {label}
            </h3>
            <Button
                round
                variant={"outlined"}
                color={"secondary"} fullWidth style={{marginBottom: 20}}
                onClick={() => {
                    window.open(pageType === "public" ? "/" : "/internal/home")
                }}
            >
                Preview
            </Button>
            {
                order.map(
                    (item, index) => (
                        <ContentPreview
                            key={item.id}
                            index={index}
                            item={item}
                            updateOrder={updateOrder}
                            updatePosition={updatePosition}
                            setDeletePage={() => setDeletePage(item)}
                            edit={() => {
                                switch (item.type) {
                                    case "html":
                                    default:
                                        history.push("/admin/edit-page", {
                                            id: item.id,
                                            pageType: pageType
                                        });
                                        break;
                                    case "image":
                                        editImage(item);
                                        break;
                                    case "signup":
                                        editSignup(item.id);
                                        break;
                                    case "locations":
                                        editLocations(item);
                                        break;
                                    case "rooms":
                                        editRooms(item);
                                        break;
                                    case "personIntro":
                                        editPersonIntro(item);
                                        break;
                                    case "countdown":
                                        editCountdown(item);
                                        break;
                                    case "timeline":
                                        editTimeline(item);
                                        break;
                                }
                            }}
                        />
                    )
                )
            }
            <div className={"empty-page"} onClick={() => setOpenMenu(true)} ref={AddElementRef}>
                <AddIcon fontSize={"large"}/>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={AddElementRef.current}
                keepMounted
                open={openMenu}
                onClose={() => setOpenMenu(false)}
            >
                {
                    PAGE_TYPES[pageType].map(
                        (item, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    setOpenMenu(false);
                                    createNew(item.value);
                                }}
                            >
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </MenuItem>
                        )
                    )
                }
            </Menu>
            {
                orderChanged && (
                    <>
                        <Button
                            color={"secondary"}
                            variant={"contained"}
                            onClick={async () => {
                                try {
                                    const result = await editPages(order, pageType);
                                    setItems(result);
                                    setLoading(false);
                                } catch (e) {
                                    console.error(e.message);
                                    setLoading(false);
                                }
                            }}
                            style={{width: "100%"}}
                        >
                            Speichern
                        </Button>
                        <Button
                            onClick={reset}
                            style={{width: "100%", marginTop: 10}}
                        >
                            Abbrechen
                        </Button>
                    </>
                )
            }

        </div>
    )

}
