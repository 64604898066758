import React from "react";
import RoomItem from "./Item";

export default function RoomsPage(props){
    const {item} = props;
    return (
        <>
            <div style={{backgroundColor: "var(--backgroundSecondaryLight)"}}>
                <div className={"center-text content-box"} style={{paddingBottom: 40}}>
                    <h1>{item.rooms.title}</h1>
                    <span>{item.rooms.text}</span>
                </div>
            </div>
            <div className={"big-content-box"}>
                {
                    item.rooms.items.map(
                        (item, index) => (
                            <RoomItem key={index} item={item} />
                        )
                    )
                }
            </div>
        </>
    );
}
