import React from "react";
// import {useMobileListener} from "../../../hooks/layout";

export default props => {
    const {item, preview} = props;
    // const isMobile = useMobileListener();
    const style = props.style ? props.style : {};
    // if(!isMobile){
        if(item.style && item.style.height){
            style.height = preview ? item.style.height : item.style.height + "vh";
            style.width = "unset";
        }
    // }
    return (
        <div className={"center-text image-content"} style={props.containerStyle}>
            <img
                className={item.style && item.style.type === "fullscreen" ? "fullscreen-image" : "flyer"}
                src={item.image.url}
                alt={"image"}
                style={style}
            />
            {
                (item.style && item.style.type === "fullscreen" && item.xtraContent && item.xtraContent.length > 0) && (
                    <div className={preview ? "text-content preview" : "text-content"}>
                        {
                            item.xtraContent.map(
                                (item, index) => {
                                    let color = "var(--text)";
                                    if(item.color === "secondary"){
                                        color = "var(--secondaryText)";
                                    }
                                    switch (item.type) {
                                        case "h1":
                                        default:
                                            return (<h1 key={index} style={{color: color}}>{item.text}</h1>);
                                        case "h2":
                                            return (<h2 key={index} style={{color: color}}>{item.text}</h2>);
                                        case "h3":
                                            return (<h3 key={index} style={{color: color}}>{item.text}</h3>);
                                        case "h4":
                                            return (<h4 key={index} style={{color: color}}>{item.text}</h4>);
                                        case "h5":
                                            return (<h5 key={index} style={{color: color}}>{item.text}</h5>);
                                        case "h6":
                                            return (<h6 key={index} style={{color: color}}>{item.text}</h6>);

                                    }
                                }
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}
