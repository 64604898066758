import React, {useEffect, useState} from "react";

export default function FoodOptionsIndicator(props){
    const {guestList, settings} = props;
    const [values, setValues] = useState([]);
    useEffect(
        () => {
            let foodOptions = settings.signup.foodOptions.map(item => ({value: 0, label: item}));
            Object.keys(guestList).forEach(
                listKey => {
                    if(guestList[listKey].attends){
                        if(foodOptions[parseInt(guestList[listKey].foodOption)]){
                            foodOptions[parseInt(guestList[listKey].foodOption)].value++;
                        }
                        if(guestList[listKey].guests){
                            // Guests
                            Object.keys(guestList[listKey].guests).forEach(
                                (guestKey, index) => {
                                    if(guestList[listKey].guests[guestKey].foodOption && parseInt(guestList[listKey].guests[guestKey].foodOption)){
                                        foodOptions[parseInt(guestList[listKey].guests[guestKey].foodOption)].value++;
                                    }else{
                                        foodOptions[0].value++;
                                    }
                                }
                            )
                        }
                    }
                }
            );
            setValues(foodOptions);
        },
       [guestList, settings]
    );
    return (
        <div className={"panel secondary bordered indicator-list"} style={{padding: 0, overflow: "hidden"}}>
            <h3 style={{margin: "20px 20px 10px 20px"}}>Speise Auswahl</h3>
            {
                values.map(
                    (item, index) => (
                        <div
                            key={index}
                            className={"space-horizontal"}
                            style={{padding: "10px 20px", backgroundColor: index % 2 ? "unset" : "var(--contrast-text)"}}
                        >
                            <span className={"indicator-label"}>
                                {item.label}
                            </span>
                            <span className={"indicator-number"}>
                                {item.value}
                            </span>
                        </div>
                    )
                )
            }
        </div>
    );
}
