import React, {useCallback, useState} from "react";
import Input from "../Form/Input";
import {createID} from "../../modules/functions";
import Checkbox from "../Form/Checkbox";
import Button from "../../components/Form/Button";

export default function EditAlbum (props){
    const {item, save, close, style} = props;
    const [form, setForm] = useState(item ? item : {name: "", userUpload: true, id: createID()});
    const [error, setError] = useState(null);
    const reset = useCallback(
        () => setForm(item ? item : {name: "", userUpload: true, id: createID()}),
        [item]
    );
    return (
        <div className={"align-vertical"} style={style}>
            <Input
                value={form.name}
                labelText={"Name"}
                formControlProps={{
                    fullWidth: true,
                }}
                handleChange={(e)=>{
                    setForm(o => ({
                        ...o,
                        name: e.target.value
                    }));
                }}
                error={error}
            />
            <Checkbox
                id={"userUpload"}
                label={"Gäste dürfen eigene Fotos hochladen"}
                checked={form.userUpload}
                setForm={setForm}
            />
            <div className={"align-horizontal"} style={{justifyContent: "flex-end", width: "100%"}}>
                {
                    item && (
                        <Button
                            onClick={() => {
                                close();
                                setError(null);
                                reset();
                            }}
                            color="info"
                            style={{marginRight: 10}}
                        >
                            Abbrechen
                        </Button>
                    )
                }
                <Button
                    onClick={() => {
                        if(form.name.length < 3){
                            setError("Bitte wähle einen Namen mit mehr als 2 Buchstaben")
                        }else{
                            setError(null);
                            save(form);
                            reset();
                            close();
                        }
                    }}
                    variant={"outlined"}
                    color="primary"
                >
                    {item ? "Speichern" : "Hinzufügen"}
                </Button>
            </div>
        </div>
    );
}
