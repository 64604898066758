import React, {useCallback, useEffect, useState} from "react";
import Input from "../components/Form/Input";
import Button from "../components/Form/Button";
import {useHighlightBackground} from "../hooks/layout";
import LogoWhite from "../images/logo-white.png";
import {loginUser} from "../actions/user";
import {useLocation, useHistory} from "react-router-dom";
import SignupForm from "../components/Signup/Form";
import ForgotPasswordForm from "../components/Signup/ForgotPassword";
import fire from "../modules/firebase";

export default function Login(props) {
    const location = useLocation();
    const history = useHistory();
    const [message, setMessage] = useState(props.message ? props.message : location.state && location.state.message ? location.state.message : null);
    const [form, setForm] = useState({
        email: "",
        password: ""
    });
    const [error, setError] = useState({});
    const [register, setRegister] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    useHighlightBackground();
    useEffect(
        () => {
            if(fire.auth().currentUser && !fire.auth().currentUser.isAnonymous){
                history.push("/internal/home");
            }
        },
        []
    );
    return (
        <div className={"align-vertical fixed-width"} style={{marginBottom: 40}}>
            <div onClick={() => history.push("/")}>
                <img
                    src={LogoWhite}
                    style={{
                        width: "100%",
                        margin: "20px auto",
                        cursor: "pointer"
                    }}
                    alt={"logo"}
                />
            </div>
            {
                message && (<div className={"panel"}>{message}</div>)
            }
            <form className="panel">
                <Input
                    labelText="Email"
                    id="email"
                    formControlProps={{
                        fullWidth: true
                    }}
                    setForm={setForm}
                    type="text"
                    error={error.email}
                />
                <Input
                    labelText="Passwort"
                    id="password"
                    formControlProps={{
                        fullWidth: true,
                        style: {marginTop: 20}
                    }}
                    setForm={setForm}
                    type="password"
                    error={error.password}
                />

                <Button
                    type="button" color="primary"
                    style={{marginTop: 40}}
                    variant={"contained"}
                    size={"large"}
                    onClick={
                        async () => {
                            props.setLoading(true);
                            setError({});
                            try{
                                await loginUser(form);
                                props.setLoading(false);
                                history.push("/internal/home");
                            }catch (e) {
                                props.setLoading(false);
                                if(e.email || e.password){
                                    setError(e);
                                }else{
                                    setMessage(e.info);
                                }
                            }
                        }
                    }
                >
                    Login
                </Button>
            </form>
            <div className={"center-text contrast-text"} style={{marginTop: 40}}>
                Du hast dich noch nicht für unsere Feier angemeldet?
            </div>
            {
                !register ? (
                    <Button
                        variant={"outlined"}
                        color={"white"}
                        onClick={() => setRegister(true)}
                        size={"large"}
                        fullWidth
                        style={{marginTop: 20}}
                    >
                        Anmelden
                    </Button>
                ) : (
                    <SignupForm
                        isNew
                        setLoading={props.setLoading}
                        settings={props.settings}
                        callback={() => history.push("/internal/home")}
                    />
                )
            }
            <div className={"center-text contrast-text"} style={{marginTop: 40}}>
                Du hast dich bereits angemeldet, aber hast dein Passwort vergessen?
            </div>
            {
                !forgotPassword ? (
                    <Button
                        variant={"text"}
                        color={"white"}
                        onClick={() => setForgotPassword(true)}
                        fullWidth
                        style={{marginTop: 20}}
                    >
                        Neues Passwort anfordern
                    </Button>
                ) : (
                    <ForgotPasswordForm/>
                )
            }


        </div>
    );
}
