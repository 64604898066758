import fire, {firebase} from "../modules/firebase";

export const editPages = (form, pageType, deleteImage = false) => new Promise(async (resolve, reject) => {
    try{
        await fire.firestore().collection("pages").doc(pageType).update({
            content: form
        });
        if(deleteImage){
            try{
                await fire.storage().ref(deleteImage.uri).delete();
            }catch (e) {
                console.error(e);
            }
        }
        resolve(form);
    }catch (e) {
        reject(e);
    }
});

export const uploadImage = (file, id, setProgress) => new Promise(async (resolve, reject) => {
    try{
        const path = "pages/";
        let fileName;
        switch (file.type) {
            case "image/png":
                fileName = id + '.png';
                break;
            case "image/jpg":
                fileName = id + '.jpg';
                break;
            case "image/jpeg":
                fileName = id + '.jpeg';
                break;
            case "image/svg":
                fileName = id + '.svg';
                break;
            case "image/gif":
                fileName = id + '.gif';
                break;
            default:
                fileName = id + '.png';
        }
        const ref = fire.storage().ref(path + fileName);
        const uploadTask = ref.put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(prog);
            }, function(error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        reject("Nicht authorisiert. Gehe sicher dass du angemeldet bist.");
                        break;

                    case 'storage/canceled':
                        reject("Upload vom Nutzer abgebrochen.");
                        break;

                    case 'storage/unknown':
                    default:
                        reject(error.message);
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            }, function() {
                const snapshot = uploadTask.snapshot;
                ref.getDownloadURL().then(
                    url => {
                        let img = new Image();
                        img.onload = () => {
                            resolve({uri: path + fileName, url: url, width: img.width, height: img.height, data: {link: url}});
                        };
                        img.src = url;
                    }
                ).catch(e => reject(e.message));
            }
        );
    }catch (e) {
        reject(e.message);
    }
});
