import React from "react";
import PersonItem from "./Item";

export default function LocationsPage(props){
    const {item} = props;
    return (
        <>
            <div style={{backgroundColor: "var(--backgroundSecondaryLight)"}}>
                <div className={"center-text content-box"} style={{paddingBottom: 40}}>
                    <h1>{item.personIntro.title}</h1>
                    <span>{item.personIntro.text}</span>
                </div>
            </div>
            <div className={"big-content-box person-items"}>
                {
                    item.personIntro.items.map(
                        (item, index) => (
                            <PersonItem key={index} item={item} />
                        )
                    )
                }
            </div>
        </>
    );
}
