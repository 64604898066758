import React, {useState} from "react";
import Button from "../../components/Form/Button";
import Pages from "../../components/Layout/Pages";
import {useHistory} from "react-router-dom";
import {logoutUser} from "../../actions/user";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {createListArray} from "../../modules/functions";
import Spinner from "../../components/Loading/Spinner";
import SignupForm from "../../components/Signup/Form";

export const guestFormValues = guestData => {
    const result = {
        attends: guestData.attends,
        numberGuests: guestData.numberGuests,
        foodOption: guestData.foodOption,
        customText: guestData.customText
    };
    if(guestData.numberGuests && guestData.numberGuests > 0){
        createListArray(guestData.guests).forEach(
            item => {
                result[`guest${parseInt(item.id) + 1}`] = item.name;
                result[`foodOption_guest${parseInt(item.id) + 1}`] = parseInt(item.foodOption);
            }
        );
    }
    return result;
};

export default function Profile(props){
    const {guestData, settings} = props;
    const [editEventRegistration, setEditEventRegistration] = useState(false);
    return(
        <Pages
            list={[
                (
                    <div className={"center-text content-box"}>
                        {
                            editEventRegistration ? (
                                <>
                                    <h2>Bearbeite deine Zusage</h2>
                                    <SignupForm
                                        defaultValues={guestFormValues(guestData)}
                                        setLoading={props.setLoading}
                                        callback={() => setEditEventRegistration(false)}
                                        settings={settings}
                                    />
                                    <Button
                                        type="button"
                                        style={{marginTop: 10}}
                                        variant={"text"}
                                        size={"large"}
                                        onClick={() => setEditEventRegistration(false)}
                                        fullWidth
                                    >
                                        Abbrechen
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h2>Deine Zusage</h2>
                                    <p>
                                        Du hast folgende Angaben zu diesem Event gemacht:
                                    </p>
                                    <div className={"panel bordered"} style={{textAlign: "left", paddingTop: 10, paddingBottom: 10}}>
                                        <div className={"align-horizontal"}>
                                            {
                                                !guestData.name ? (
                                                    <Spinner width={50} height={50} />
                                                ) : guestData.attends ? (
                                                    <CheckIcon color={"primary"} fontSize={"large"}/>
                                                ) : (
                                                    <CloseIcon color={"error"} fontSize={"large"}/>
                                                )
                                            }
                                            <div className={"align-vertical"} style={{marginLeft: 20}}>
                                                {
                                                    !guestData.name ? (
                                                        <h3 style={{color: "var(--primary)"}}>Lade Daten...</h3>
                                                    ) : guestData.attends ? (
                                                        <h3 style={{color: "var(--primary)"}}>
                                                            {guestData.numberGuests === 0 ? (
                                                                "Du hast zugesagt."
                                                            ) : (
                                                                `Du hast für dich und ${guestData.numberGuests} andere Person zugesagt`
                                                            )}
                                                        </h3>
                                                    ) : (
                                                        <h3 style={{color: "var(--error)"}}>Du hast abgesagt.</h3>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            (guestData.attends && guestData.numberGuests > 0) && (
                                                <ul className={"simpleList"}>
                                                    {
                                                        createListArray(guestData.guests).map(
                                                            (item, index) => (
                                                                <li key={index} className={index % 2 ? "even" : "uneven"}>
                                                                    <div className={"space-horizontal"}>
                                                                        <strong>{item.name}</strong>
                                                                        <span className={"value-preview"}>{settings.signup.foodPreferences && settings.signup.foodOptions[item.foodOption]}</span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                    <li className={createListArray(guestData.guests).length % 2 ? "even" : "uneven"}>
                                                        <div className={"space-horizontal"}>
                                                            <strong>Du</strong>
                                                            <span className={"value-preview"}>{settings.signup.foodPreferences && settings.signup.foodOptions[guestData.foodOption]}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            )
                                        }
                                    </div>
                                    {
                                        guestData.name && (
                                            <Button
                                                round
                                                variant={"contained"}
                                                onClick={() => {
                                                    setEditEventRegistration(true);
                                                }}
                                                color={"secondary"}
                                                standardWidth
                                                style={{marginTop: 20}}
                                            >
                                                Angaben ändern
                                            </Button>
                                        )
                                    }

                                </>
                            )
                        }
                    </div>
                ),
                (
                    <div className={"center-text content-box"}>
                        <h2>Dies ist nicht dein Account?</h2>
                        <p>
                            Bitte logge dich aus und melde dich mit deinem Namen neu an!
                        </p>
                        <Button
                            round
                            variant={"outlined"}
                            onClick={async () => {
                                try{
                                    await logoutUser();
                                }catch (e) {
                                    console.error(e);
                                }
                            }}
                            standardWidth
                        >
                            Logout
                        </Button>
                    </div>
                )
            ]}
        />
    )

}
