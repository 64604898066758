import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {motion, useAnimation} from "framer-motion";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';

export default function BottomActionBar(props){
    const {visible, close, items, moveImages, deleteImages} = props;
    const controls = useAnimation();
    let styles = {};
    useEffect(
        () => {
            if(visible){
                controls.start({
                    bottom: 50,
                    transition: { duration: 0.5 },
                })
            }else{
                controls.start({
                    bottom: -150,
                    transition: { duration: 0.5 },
                });
            }
        },
        [visible]
    );
    return(
        <motion.div
            className={"bottom-action-bar"}
            animate={controls}
        >
            <Card  elevation={3} style={styles}>
                <CardContent className={"space-horizontal"}>
                    <span>{items && items.length > 0 ? `${items.length} ausgewählte Fotos` : "Wähle Fotos um sie zu verschieben"}</span>
                    <div className={"align-horizontal"}>
                        {
                            (items && items.length > 0) && (
                                <>
                                    <IconButton
                                        aria-label="move"
                                        onClick={moveImages}
                                    >
                                        <PhotoAlbumIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={deleteImages}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )
                        }

                        <IconButton
                            aria-label="close"
                            onClick={close}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
}
