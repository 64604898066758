import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import fire from "../../modules/firebase";
import {safeFormat, safeRelative} from "../../modules/time";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

export default props => {
    const {item, user, setDelete, setEdit, preview} = props;
    const isMy = item.user.uid === fire.auth().currentUser.uid;
    const [submenu, setSubmenu] = useState(false);
    const postRef = useRef();
    const classes = classNames({
        ["post-item"]: true,
        ["my-post"]: isMy
    });
    return (
        <div className={classes}>
            <div className={"post-message"} onContextMenu={e => {
                if(!preview && (user.isAdmin || isMy)){
                    e.preventDefault();
                    setSubmenu(o => !o);
                }
            }}>
                <div className={"post-name"}>
                    {isMy ? "Du" : item.user.name}
                </div>
                {item.text.split("\n").map(item => <div>{item}</div>)}
                <div className={"post-time"}>
                    {safeRelative(item.time)}
                </div>
                {
                    item.edited && (
                        <div className={"post-time"}>
                            Bearbeitet: {safeRelative(item.edited)}
                        </div>
                    )
                }
            </div>
            <div ref={postRef} />
            {
                (user.isAdmin || isMy) && (
                    <>
                        <Menu
                            id="simple-menu"
                            anchorEl={postRef.current}
                            keepMounted
                            open={submenu}
                            onClose={() => setSubmenu(false)}
                        >
                            <MenuItem
                                onClick={() => {
                                    setSubmenu(false);
                                    setEdit(item);
                                }}
                            >
                                Bearbeiten
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setSubmenu(false);
                                    setDelete(item);
                                }}
                            >
                                Löschen
                            </MenuItem>
                        </Menu>
                    </>
                )
            }
        </div>
    );
}
