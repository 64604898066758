import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import "./button.css";
import classNames from "classnames";

// @material-ui/core components
import Button from "@material-ui/core/Button";

const RegularButton = React.forwardRef((props, ref) => {
    const {
        color,
        round,
        children,
        fullWidth,
        disabled,
        simple,
        size,
        block,
        link,
        icon,
        className,
        standardWidth,
        ...rest
    } = props;


    const btnClasses = classNames({
        "button": true,
        [`button_${size}`]: size,
        [`button_${color}`]: color,
        "button_round": round,
        "button_fullWidth": fullWidth,
        "button_disabled": disabled,
        "button_icon": icon,
        [className]: className,
        "button_highlight": color === "highlight",
        "button_standardWidth": standardWidth
    });
    return (
        <Button {...rest} disabled={disabled} ref={ref} className={btnClasses} color={color === "highlight" ? "primary" : color} size={size}>
            {children}
        </Button>
    );
});

RegularButton.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "facebook",
        "twitter",
        "google",
        "github",
        "transparent"
    ]),
    size: PropTypes.oneOf(["sm", "lg"]),
    simple: PropTypes.bool,
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    icon: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string
};

export default RegularButton;
