import React, {useEffect} from "react";
import Header from "./Header";
import {useLocation} from "react-router-dom";

export default function InternalLayout(props){
    const location = useLocation();
    useEffect(
        () => {
            window.scrollTo(0, 0);
        },
        [location.pathname]
    );
    return(
        <>
            <Header
                settings={props.settings}
                user={props.user}
            />
            <main className={"internal-layout-content"}>
                {props.children}
            </main>
        </>
    );
}
