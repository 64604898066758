import React, {useEffect, useState} from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {colors} from "../../constants";

const imgStyle = {
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const selectedImgStyle = {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
    transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
};
const cont = {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative"
};

export default function SelectedImage(props) {
    const { index, photo, margin, direction, top, left, selected, add, remove} = props;
    const [isSelected, setIsSelected] = useState(selected);
    //calculate x,y scale
    const sx = (100 - (30 / photo.width) * 100) / 100;
    const sy = (100 - (30 / photo.height) * 100) / 100;
    selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

    if (direction === "column") {
        cont.position = "absolute";
        cont.left = left;
        cont.top = top;
    }

    const handleOnClick = e => {
        setIsSelected(!isSelected);
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    useEffect(() => {
        if(isSelected){
            add();
        }else{
            remove();
        }
    }, [isSelected]);

    return (
        <div
            style={{ margin, height: photo.height, width: photo.width, ...cont }}
            className={!isSelected ? "not-selected" : ""}
        >
            <div style={
                isSelected
                    ? { left: "4px", top: "4px", position: "absolute", zIndex: "1" }
                    : { display: "none" }
            }>
                <CheckCircleIcon color={"secondary"} style={{backgroundColor: "white", borderRadius: 15}}/>
            </div>
            <img
                alt={photo.title}
                style={
                    isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }
                }
                {...photo}
                onClick={handleOnClick}
            />
            <style>{`.not-selected:hover{outline:2px solid ${colors.secondary}`}</style>
        </div>
    );
};
