import React from 'react';
import "./spinner.css";

export default props => (
    <div className="lds-css ng-scope" >
        <div className="lds-spinner" style={{
            width: props.width,
            height: props.width,
            WebkitTransform: "translate(-" + Math.round( props.width * 0.5)
                + "px, -" + Math.round( props.width * 0.5) + "px) scale(1) translate("
                + Math.round( props.width * 0.5) + "px, " + Math.round( props.width * 0.5) + "px)",
            transform: "translate(-" + Math.round( props.width * 0.5) + "px, -" + Math.round( props.width * 0.5)
                + "px) scale(1) translate(" + Math.round( props.width * 0.5) + "px, "
                + Math.round( props.width * 0.5) + "px)"
        }}>
            {
                [1,2,3,4,5,6,7,8,9,10,11,12].map(
                    (key) => (
                        <div key={key} style={{
                            left: Math.round(props.width * 0.47),
                            top: Math.round(props.width * 0.24),
                            width: Math.round(props.width * 0.06),
                            height: Math.round(props.width * 0.12),
                            WebkitTransformOrigin: Math.round(props.width * 0.03) + "px "
                                + Math.round(props.width * 0.26) + "px",
                            transformOrigin: Math.round(props.width * 0.03) + "px "
                                + Math.round(props.width * 0.26) + "px"
                        }} />
                    )
                )
            }
        </div>
    </div>
);
