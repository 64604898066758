import React, {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Editor from "../../components/ContentPages/ContentEditor";
import Button from "../../components/Form/Button";
import {editPages, uploadImage} from "../../actions/pages";
import {createID} from "../../modules/functions";

export default function EditPage(props){
    const {items, label, edit, publicPages, internalPages, setLoading, setPublicPages, setInternalPages} = props;
    const history = useHistory();
    const location = useLocation();
    const {id, pageType} = location.state;
    const pages = pageType === "internal" ? [...internalPages] : [...publicPages];
    const index = pages.findIndex(item => item.id === id);
    const [form, setForm] = useState({
        content: index > -1 ? pages[index].html : "",
        type: "html"
    });
    return(
        <div className={"center-text content-box"}>
            <Editor
                defaultValue={form.content}
                onChange={v => setForm(o => ({...o, content: v}))}
                style={{width: "100%"}}
                toolbar={{
                    options: ['blockType', 'inline', 'list', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                    },
                    image: {
                        uploadCallback: file => uploadImage(file, `${id}/${createID()}`, () => null),
                        uploadEnabled: true,
                        previewImage: true,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    }
                }}
            />
            <div className={"action-buttons-wrapper"}>
                <div className={"action-buttons"}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async () => {
                            setLoading(true);
                            try{
                                const item = {
                                    id: id,
                                    type: "html",
                                    html: form.content
                                };
                                if(index > -1){
                                    // Edit
                                    pages[index] = item;
                                }else{
                                    // New
                                    pages.push(item);
                                }
                                const result = await editPages(pages, pageType);
                                if(pageType === "internal"){
                                    setInternalPages(result);
                                }else{
                                    setPublicPages(result);
                                }
                                setLoading(false);
                                history.push("/admin/settings");
                            }catch (e) {
                                setLoading(false);
                            }
                        }}
                    >
                        Speichern
                    </Button>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        onClick={() => history.goBack()}
                    >
                        Abbrechen
                    </Button>
                </div>
            </div>
        </div>
    )

}
