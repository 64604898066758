import React, { useState, useEffect, useRef } from 'react';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import classNames from "classnames";
import "./weddingIcon.css";

const Icon = ({ name, size=64, className, white, ...p}) => {
    const ImportedIconRef = useRef(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const importIcon = async () => {
            try {
                // BUGFIX --> change to import(...).ReactComponent when react scripts dates up
                ImportedIconRef.current = (
                    await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../images/icons/wedding/${name}`)
                ).default;
            } catch (err) {
                throw err;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
    }, [name]);

    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        const classes = classNames({
            [className]: !!className,
            white
        });
        return <ImportedIcon viewBox={"0 0 75 75"} width={size} height={size} className={classes} {...p} />;
    }
    return <HourglassEmptyIcon width={size} height={size} className={className} {...p} />;
};

export default Icon;
