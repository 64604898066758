import {useEffect, useState} from "react";
import fire from "../modules/firebase";
import {DEFAULT_SETTINGS} from "../constants";


export const useSettings = () => {
    const [settings, setSettings] = useState(DEFAULT_SETTINGS);
    useEffect(
        () => {
            fire.firestore().collection("admin").doc("settings").get().then(
                doc => {
                    const data = doc.data();
                    setSettings(data);
                }
            ).catch(
                e => {
                    console.error(e.message);
                }
            )
        },
        []
    );
    return [settings, setSettings];
};
