import React, {useCallback, useEffect, useState} from 'react';
import Button from '../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "./Transition";
import {editPages} from "../../actions/pages";
import Select from "../Form/Select";
import DialogActions from '@material-ui/core/DialogActions';
import {changeSignupSettings} from "../../actions/settings";
import Input from "../Form/Input";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "../Form/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import addDate from 'date-fns/add';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DatePicker from "../Form/DatePicker";

const prepareSettings = settings => {
    let result = {...settings};
    if(!settings.closingTime){
        // Default Date in 3 Months
        result.closingTime = addDate(new Date(), {months: 3}).getTime();
    }
    return result;
};

export default function SignupPreferences(props) {
    const {open, close, item, pages, setPages, signupSettings, setSignupSettings} = props;
    const [error, setError] = useState({});
    const [form, setForm] = useState(prepareSettings(signupSettings));
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(
        () => {
            setForm(prepareSettings(signupSettings));
        },
        [signupSettings]
    );
    const callback = useCallback(
        async (form) => {
            try{
                const f = {
                    id: item.id,
                    type: "signup"
                };
                const index = pages.findIndex(i => i.id === item.id);
                if(index > -1){
                    // Edit
                    pages[index] = f;
                }else{
                    // New
                    pages.push(f);
                }
                const newPages = await editPages(pages, item.pageType);
                setPages(newPages);
                await changeSignupSettings(form);
                setSignupSettings(form);
                close();
            }catch (e) {
                if(e.message){
                    setError({info: e.message});
                }else{
                    setError(e);
                }
            }
        },
        [pages, item]
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            fullScreen={fullScreen}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Einstellung für die Gästeanmeldung</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <form>
                        Wieviele Gäste darf eine Person maximal mitnehmen?
                        <Select
                            labelText={"Maximale Anzahl zusätzlicher Gäste"}
                            id="maxAddGuests"
                            formControlProps={{
                                fullWidth: true,
                                style: {marginTop: 20, marginBottom: 20}
                            }}
                            value={form.maxAddGuests}
                            setForm={setForm}
                            error={error.maxAddGuests}
                            options={(Array.from(Array(21).keys())).map((i, index) => ({value: index, label: index}))}
                        />
                        Bis wann kann man sich für die Feier anmelden oder seine Angaben ändern?
                        <DatePicker
                            timestampValue={form.closingTime}
                            onChange={e => setForm(o => ({...o, closingTime: e.getTime()}))}
                        />
                        <div style={{marginTop: 20}}>
                            <Accordion elevation={3}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    Individuelles Eingabefeld
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={"stretch-vertical"}>
                                        <Checkbox
                                            id={"customTextfield"}
                                            label={"Individuelles Eingabefeld bei der Registrierung anbieten"}
                                            checked={form.customTextfield}
                                            setForm={setForm}
                                        />
                                        {
                                            form.customTextfield && (
                                                <Input
                                                    multiline
                                                    defaultValue={form.customTextfieldText}
                                                    labelText="Text"
                                                    id="customTextfieldText"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    setForm={setForm}
                                                    error={error.customTextfieldText}
                                                />
                                            )
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion elevation={3}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    Individuelle Angaben zu Speisen
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={"stretch-vertical"}>
                                        <Checkbox
                                            id={"foodPreferences"}
                                            label={"Optionen für Speisen anbieten"}
                                            checked={form.foodPreferences}
                                            setForm={setForm}
                                        />
                                        {
                                            form.foodPreferences && (
                                                form.foodOptions.map(
                                                    (item, index) => (
                                                        <div className={"align-horizontal"}>
                                                            <Input
                                                                value={item}
                                                                labelText={`Option ${index + 1}${index === 0 ? " (Standard)" : ""}`}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    style: {
                                                                        marginTop: index > 0 ? 20 : 10
                                                                    }
                                                                }}
                                                                handleChange={(e)=>{
                                                                    let array = form.foodOptions;
                                                                    array[index] = e.target.value;
                                                                    setForm(o => ({
                                                                        ...o,
                                                                        foodOptions: array
                                                                    }));
                                                                }}
                                                            />
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={
                                                                    () => {
                                                                        let array = form.foodOptions;
                                                                        if(array.length > 1){
                                                                            array.splice(index, 1);
                                                                            setForm(o => ({
                                                                                ...o,
                                                                                foodOptions: array
                                                                            }));
                                                                        }
                                                                    }
                                                                }
                                                            >
                                                                <DeleteIcon color={"error"}/>
                                                            </IconButton>
                                                        </div>
                                                    )
                                                )
                                            )
                                        }
                                        {
                                            form.foodPreferences && (
                                                <Button
                                                    onClick={() => setForm(o => ({
                                                        ...o,
                                                        foodOptions: [...o.foodOptions, ""]
                                                    }))}
                                                    color="primary"
                                                    round
                                                    variant={"outlined"}
                                                    style={{marginTop: 20}}
                                                >
                                                    Option hinzufügen
                                                </Button>
                                            )
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion elevation={3}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    Individuelle Texte
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={"stretch-vertical"}>
                                        <Input
                                            multiline
                                            defaultValue={form.title}
                                            labelText="Überschrift"
                                            id="title"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            setForm={setForm}
                                            error={error.title}
                                        />
                                        <Input
                                            multiline
                                            defaultValue={form.titleAdditionalGuests}
                                            labelText="Überschrift für weitere Gäste"
                                            id="titleAdditionalGuests"
                                            formControlProps={{
                                                fullWidth: true,
                                                style: {marginTop: 20}
                                            }}
                                            setForm={setForm}
                                            error={error.titleAdditionalGuests}
                                        />
                                        <Input
                                            multiline
                                            defaultValue={form.infoText}
                                            labelText="Info Text"
                                            id="infoText"
                                            formControlProps={{
                                                fullWidth: true,
                                                style: {marginTop: 20}
                                            }}
                                            setForm={setForm}
                                            error={error.infoText}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </form>

                </DialogContentText>
                {
                    error.info && (
                        <span className={"error-text"}>
                            {error.info}
                        </span>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="info">
                    Abbrechen
                </Button>
                <Button onClick={() => callback(form)} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
}
