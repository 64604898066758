import React, {useCallback} from "react";
import { CSVLink } from "react-csv";
import Button from "../../components/Form/Button";

export default props => {
    const {guestListArray, settings} = props;
    // TODO: Adressen hinzufügen (und bei Registrierung)
    const prepareData = useCallback(
        (array) => {
            let data = [];
            let count = 1;
            array.forEach(
                (item) => {
                    if(item.attends){
                        const entry = {
                            index: count,
                            name: item.name,
                            email: item.email
                        };
                        if(settings.signup.foodPreferences && item.foodOption){
                            entry.foodOption = settings.signup.foodOptions[item.foodOption];
                        }
                        data.push(entry);
                        count ++;
                        if(item.numberGuests > 0 ){
                            Object.keys(item.guests).forEach(
                                key => {
                                    const entry = {
                                        index: count,
                                        name: item.guests[key].name,
                                        email: item.email
                                    };
                                    if(settings.signup.foodPreferences && item.guests[key].foodOption){
                                        entry.foodOption = settings.signup.foodOptions[item.guests[key].foodOption];
                                    }
                                    data.push(entry);
                                    count ++;
                                }
                            )
                        }
                    }
                }
            );
            return data;
        },
        [settings]
    );
    let headers = [
        {label: "Nr", key: "index"},
        {label: "Name", key: "name"},
        {label: "E-mail", key: "email"}
    ];
    if(settings.signup.foodPreferences){
        headers.push({label: "Essenswünsche", key: "foodOption"});
    }
    const data = prepareData(guestListArray);
    return (
        <Button
            standardWidth
            variant={"outlined"}
            round
            color={"primary"}
            style={props.style}
            // onClick={() => window.open(`mailto:${guestListArray.map(item => item.email).join(",").slice(0, -1)}`)}
        >
            <CSVLink
                data={data}
                headers={headers}
                filename={"gaeste-liste.csv"}
            >
                Download Gästeliste
            </CSVLink>
        </Button>
    );
}
