import React, {useCallback, useEffect, useState} from "react";
import Button from "../../components/Form/Button";
import Gallery from "react-photo-gallery";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useMobileListener} from "../../hooks/layout";
import CloudDownload from '@material-ui/icons/CloudDownload';
import {useDropzone} from "react-dropzone";
import {deletePhoto, deletePhotos, movePhotosToAlbums, setFavorite, uploadImages} from "../../actions/photos";
import ProgressBar from "../../components/Loading/ProgressBar";
import {usePhotos} from "../../hooks/photos";
import Select from "../../components/Form/Select";
import {safeFormat} from "../../modules/time";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from "../../components/Dialogs/Delete";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Tooltip from "@material-ui/core/Tooltip";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import AlbumButton from "../../components/Photos/AlbumButton";
import SelectImage from "../../components/Photos/SelectImage";
import BottomActionBar from "../../components/Photos/BottomActionBar";
import MoveToAlbumDialog from "../../components/Photos/MoveToAlbumDialog";

const sortOptions = [
    {value: "uploaded", label: "Neueste Fotos"},
    {value: "fileModified", label: "Chronologische Fotos"}
];

export default function Photos(props){
    const {setLoading, settings, user} = props;
    const hasAlbums = settings.gallery && settings.gallery.albums && Object.keys(settings.gallery.albums).length > 0;
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [isUpload, setUpload] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [sortPhotos, setSortPhotos] = useState(sortOptions[0].value);
    const [deleteImage, setDeleteImage] = useState(false);
    const isMobile = useMobileListener();
    // Album
    const getFirstAlbum = useCallback(
        (albums) => {
            let id;
            Object.keys(albums).forEach(
                key => {
                    if(albums[key].index === 0){
                        id = key;
                    }
                }
            );
            return id;
        },
        []
    );
    const [album, setAlbum] = useState(hasAlbums ? getFirstAlbum(settings.gallery.albums) :  "all");
    const [photos, downloadPhoto, downloadAllPhotos, method] = usePhotos(setLoading, sortPhotos, album);
    const srcImages = photos.map(i => i.url);
    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/*',
        maxSize: 10000000,
        multiple: true,
        noDrag: false,
        noDragEventsBubbling: true,
        onDropRejected: (files, event) => {
            // setError("Beim Hochladen ist etwas schief gelaufen. Überprüfe ob die Dateien den Richtlinien entsprechen.");
        },
        onDrop: async acceptedFiles => {
            setUpload(true);
            // console.log(acceptedFiles);
            try{
                await uploadImages(acceptedFiles, user, setUploadProgress, album);
            }catch (e) {
                console.error(e);
            }
            setUpload(false);
            setUploadProgress(0);
        }
    });

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    useEffect(
        () => {
            document.body.style.overflow = viewerIsOpen ? "hidden" : "unset"
        },
        [viewerIsOpen]
    );
    // Select Images (admin)
    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
            <SelectImage
                key={key}
                margin={"2px"}
                index={index}
                photo={photo}
                left={left}
                top={top}
                direction={"column"}
                add={() => setSelectedImages(i => [...i, index])}
                remove={() => setSelectedImages(i => i.filter(b => b !== index))}
            />
        ),
        []
    );

    // Edit Images / Select Images to Edit
    const [editMultipleImages, setEditMultipleImages] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [deleteMultipleImages, setDeleteMultipleImages] = useState(false);
    const [moveMultipleImages, setMoveMultipleImages] = useState(false);
    const resetSelection = useCallback(
        () => {
            setEditMultipleImages(false);
            setDeleteMultipleImages(false);
            setMoveMultipleImages(false);
            setSelectedImages([]);
        },
        []
    );
    // Add selected Images to Albums
    const addImagesToAlbums = useCallback(
        async (images, albumsObj) => {
            console.log(1);
            const objs = images.map(i => photos[i]);
            console.log(objs);
            console.log(2);
            setLoading(true);
            console.log(3);
            try{
                await movePhotosToAlbums(objs, albumsObj);
                console.log(4);
            }catch (e) {
                console.error(e);
            }
            console.log(5);
            setLoading(false);
            console.log(6);
            resetSelection();
            console.log(7);
        },
        [photos, resetSelection]
    );
    // Delete selected Images
    const deleteImages = useCallback(
        async (images) => {
            const objs = images.map(i => photos[i]);
            setLoading(true);
            try{
                await deletePhotos(objs);
            }catch (e) {
                console.error(e);
            }
            setLoading(false);
            resetSelection();
        },
        [photos, resetSelection]
    );
    useEffect(
        () => {
            resetSelection();
        },
        [album]
    );
    // Image Caption
    const getCaption = image => (
        <div style={{color: "#a0a0a0"}}>
            Von <strong style={{color: "white"}}>{image.user}</strong> am {
                safeFormat(sortPhotos === "uploaded" ? image.uploaded : image.fileModified, "PPP")
            }
        </div>
    );
    // Toolbar Buttons
    let getToolbarButtons = image => {
        let buttons = [];

        if(user.data.isAdmin){
            // Bride
            buttons.push(
                <Tooltip title={image.favorite ? "Favorisiert" : "Favorisieren"} >
                    <div
                        className={"ril-toolbar-button"}
                        onClick={
                            () => setFavorite(image, !image.favorite)
                        }
                    >
                        {
                            image.favorite ? (
                                <FavoriteIcon />
                            ) : (
                                <FavoriteBorderIcon />
                            )
                        }

                    </div>
                </Tooltip>
            );
        }else if(image.favorite){
            buttons.push(
                <Tooltip title={"Vom Brautpaar favorisiert"} >
                    <div
                        className={"ril-toolbar-button"}
                    >
                        <FavoriteIcon />
                    </div>
                </Tooltip>
            );
        }
        if(image.uid === user.uid || user.data.isAdmin){
            // Owner of Image or Bride
            buttons.push(
                <Tooltip title={"Löschen"} >
                    <div
                        className={"ril-toolbar-button"}
                        onClick={
                            () => setDeleteImage(image)
                        }
                    >
                        <DeleteIcon color="error" />
                    </div>
                </Tooltip>
            );
        }
        if(!isMobile){
            buttons.push(
                <Tooltip title={"Herunterladen"} >
                    <div
                        className={"ril-toolbar-button"}
                        onClick={
                            () => downloadPhoto(currentImage, srcImages[currentImage])
                        }
                    >
                        <CloudDownload />
                    </div>
                </Tooltip>
            );
        }
        return buttons;
    };

    if(!(settings.gallery && settings.gallery.active && settings.gallery.startDate < (new Date()).getTime())){
        // No Gallery (yet)
        return (
            <div>
                <div className={"center-text content-box"}>
                    <h2>Gallery nicht verfügbar</h2>
                </div>
            </div>
        );
    }
    return(
        <>
            {
                // Dialogs
            }
            <DeleteDialog
                open={!!deleteImage}
                fail={() => setDeleteImage(false)}
                success={async () => {
                    try {
                        // Delete Image
                        setDeleteImage(false);
                        // setViewerIsOpen(false);
                        setLoading(true);
                        await deletePhoto(deleteImage);
                        setLoading(false);
                    } catch (e) {
                        console.error(e.message);
                    }
                }}
                item={deleteImage && <img src={deleteImage.thumb ? deleteImage.thumb.url : deleteImage.url} width={200}/>}
            />
            <DeleteDialog
                open={!!deleteMultipleImages}
                fail={() => setDeleteMultipleImages(false)}
                success={async () => {
                    try {
                        await deleteImages(selectedImages);
                    } catch (e) {
                        console.error(e.message);
                    }
                }}
                item={(selectedImages && selectedImages.length) && <span>{selectedImages.length} ausgewählte Fotos</span>}
            />
            <MoveToAlbumDialog
                open={!!moveMultipleImages}
                close={() => setMoveMultipleImages(false)}
                settings={settings}
                items={selectedImages}
                save={albums => addImagesToAlbums(selectedImages, albums)}
            />
            {
                // Rendered Items
            }
            {
                hasAlbums && (
                    <div className={"group-bar"}>
                        <label style={{marginLeft: 10}}>Alben</label>
                        {
                            Object.keys(settings.gallery.albums).sort((a, b) => settings.gallery.albums[a].index >= settings.gallery.albums[b].index ? 1 : -1).map(
                                id => (
                                    <AlbumButton
                                        key={id}
                                        name={settings.gallery.albums[id].name}
                                        active={id === album}
                                        onClick={() => setAlbum(id)}
                                    />
                                )
                            )
                        }
                        <AlbumButton
                            name={"Alle Fotos"}
                            active={"all" === album}
                            onClick={() => setAlbum("all")}
                        />
                    </div>
                )
            }
            <div className={"full-height-page"} style={{padding: 20}}>
                <div style={{display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "flex-end", alignItems: "center"}}>
                    <Select
                        formControlProps={{
                            style: {marginRight: isMobile ? 0 : 20, marginBottom: isMobile ? 20 : 0},
                            variant: "outlined"
                        }}
                        handleChange={e => setSortPhotos(e.target.value)}
                        value={sortPhotos}
                        options={sortOptions}

                    />
                    {
                        !isMobile && (
                            <Button
                                size={"large"}
                                color={"primary"}
                                variant={"outlined"}
                                onClick={downloadAllPhotos}
                            >
                                <CloudDownload style={{marginRight: 10}}/> Fotos herunterladen
                            </Button>
                        )
                    }
                    {
                        (user.data.isAdmin && hasAlbums) && (
                            <Button
                                size={"large"}
                                color={"primary"}
                                variant={editMultipleImages ? "contained" : "outlined"}
                                onClick={() => setEditMultipleImages(v => !v)}
                                style={{marginLeft: isMobile ? 0 : 20, marginTop: isMobile ? 20 : 0}}
                            >
                                <PhotoAlbumIcon style={{marginRight: 10}}/> Verschiebe Fotos
                            </Button>
                        )
                    }
                </div>
            </div>
            {
                (!hasAlbums || (album !== "all" && (settings.gallery.albums[album].userUpload || user.data.isAdmin))) && (
                    <div className={"full-height-page"} style={{backgroundColor: "var(--backgroundPrimaryLight)"}}>
                        <div className={"center-text content-box"}>
                            <h1>Teile deine Eindrücke von unserer Feier</h1>
                            <p>Hier könnt ihr einfach eure Fotos hochladen und teilen.</p>
                            {
                                isUpload ? (
                                    <ProgressBar progress={uploadProgress}/>
                                ) : (
                                    <Button
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={open}
                                    >
                                        Hochladen
                                    </Button>
                                )
                            }
                            <input {...getInputProps()} />
                        </div>
                    </div>
                )
            }

            <div>
                {
                    photos.length > 0 ? (
                        <Gallery
                            photos={photos.map(item => item.thumb
                                ? ({src: item.thumb.url, width:item.width, height: item.height})
                                : ({src: item.url, width:item.width, height: item.height})
                            )}
                            renderImage={editMultipleImages ? imageRenderer : undefined}
                            direction={"column"} onClick={openLightbox} lazyLoad
                        />
                    ) : (
                        <div className={"center-text content-box"}>
                            <h3>Es wurden noch keine Photos hinzugefügt</h3>
                        </div>
                    )
                }
                <BottomActionBar
                    visible={editMultipleImages}
                    close={() => setEditMultipleImages(false)}
                    items={selectedImages}
                    moveImages={() => setMoveMultipleImages(true)}
                    deleteImages={() => setDeleteMultipleImages(true)}
                />
                {viewerIsOpen && (
                    <Lightbox
                        mainSrc={srcImages[currentImage]}
                        nextSrc={srcImages[(currentImage + 1) % srcImages.length]}
                        prevSrc={srcImages[(currentImage + srcImages.length - 1) % srcImages.length]}
                        onCloseRequest={closeLightbox}
                        onMovePrevRequest={() =>
                            setCurrentImage(val => (val + srcImages.length - 1) % srcImages.length)
                        }
                        onMoveNextRequest={() =>
                            setCurrentImage(val =>  (val + 1) % srcImages.length)
                        }
                        imageCaption={isMobile && getCaption(photos[currentImage])}
                        imageTitle={!isMobile && getCaption(photos[currentImage])}
                        toolbarButtons={getToolbarButtons(photos[currentImage])}
                    />
                )}
            </div>
        </>
    )

}
