import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import {useMeasurePosition} from "../../hooks/animation";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EditAlbum from "./EditAlbum";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import {getNumberOfPhotosFromAlbum} from "../../actions/photos";

export default function AlbumItem(props){
    const {updateOrder, updatePosition, item, setForm, index, setUpdated, setDelete, onlyDisplay} = props;
    const [isDragging, setDragging] = useState(false);
    const [edit, setEdit] = useState(false);
    const [numberOfPhotos, setNumberOfPhotos] = useState(false);
    const [contextMenu, setContextMenu] = useState(false);
    const ref = useMeasurePosition((pos) => updatePosition(index, pos));
    // Load number of Photos per Album
    useEffect(
        () => {
            getNumberOfPhotosFromAlbum(item.id).then(
                number =>  setNumberOfPhotos(number)
            )
        },
        []
    );
    return (
        <motion.div
            ref={ref}
            layout
            initial={false}
            style={!onlyDisplay && {position: "relative", width: "100%", cursor: "move", zIndex: isDragging ? 10 : 1, backgroundColor: isDragging ? "rgba(0, 0, 0, 0.03)" : "transparent"}}
            onMouseEnter={() => !onlyDisplay && setContextMenu(true)}
            onMouseLeave={() => !onlyDisplay && setContextMenu(false)}
            onContextMenu={() => !onlyDisplay && setContextMenu(o => !o)}

            whileTap={{
                scale: edit || onlyDisplay ? 1.0 : 0.96,
            }}
            drag={onlyDisplay ? undefined : "y"}
            onDragStart={() => !onlyDisplay && setDragging(true)}
            onDragEnd={() => !onlyDisplay && setDragging(false)}
            onViewportBoxUpdate={(_viewportBox, delta) => {
                if(isDragging){
                    updateOrder(index, delta.y.translate);
                }
            }}
            className={"space-horizontal"}
        >
            {
                edit ? (
                    <EditAlbum
                        item={item}
                        save={i => {
                            setForm(f => {
                                const form = f;
                                form.albums[index] = i;
                                setUpdated(true);
                                return form;
                            });
                        }}
                        close={() => setEdit(false)}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.03)", padding: 10, borderRadius: 5, width: "100%"}}
                    />
                ) : (
                    <div className={"align-horizontal"}><PhotoAlbumIcon /> <span style={{margin: 12}}>{item.name}</span> {numberOfPhotos !== false && `(${numberOfPhotos})`}</div>
                )
            }
            {
                (contextMenu && !edit) && (
                    <div>
                        <IconButton
                            aria-label="edit"
                            onClick={() => setEdit(true)}
                        >
                            <EditIcon color={"primary"}/>
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={() => setDelete(item, numberOfPhotos)}
                        >
                            <DeleteIcon color={"error"}/>
                        </IconButton>
                    </div>
                )
            }
        </motion.div>
    );
}
