import SignupForm from "./Form";
import React from "react";
import Button from "../../components/Form/Button";
import {useHistory} from "react-router-dom";

export default props => {
    const {setLoading, settings} = props;
    const history = useHistory();
    return (
        <div className={"center-text content-box"} style={{padding:0}}>
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <h2>{settings.signup.title}</h2>
            </div>
            <SignupForm
                isNew
                setLoading={setLoading}
                settings={settings}
                callback={() => history.push("/internal/home")}
            />
            <div style={{padding: 20}}>
                Oder melde dich an, falls du dich schon registriert hast.
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    onClick={() => history.push("/login")}
                    size={"large"}
                    fullWidth
                    style={{marginTop: 20}}
                >
                    Login
                </Button>
            </div>
        </div>
    );
}
