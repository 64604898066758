import React, {useCallback} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import TextField from "@material-ui/core/TextField";
import "./input.css";


export default function CustomInput(props) {
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        handleChange,
        disabled,
        defaultValue,
        setForm,
        type,
        variant,
        ...r
    } = props;
    const direct_handleChange = useCallback(e => {
        if(setForm){
            setForm(f => ({
                ...f,
                [id]: e.target.value
            }));
        }else{
            console.error("Need to pass setForm");
        }
    }, [id, setForm]);
    return (
        <>
            <TextField
                label={labelText}
                error={!!error}
                disabled={disabled}
                InputLabelProps={labelProps}
                InputProps={inputProps}
                id={id}
                onChange={handleChange ? handleChange : direct_handleChange}
                defaultValue={defaultValue}
                variant={variant}
                type={type}
                {...formControlProps}
                {...r}
            />
            {
                error && <span className={"error-text"}>{error}</span>
            }
        </>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.bool,
    white: PropTypes.bool
};
