import React from "react";
import Spinner from "./Spinner";

/**
 * @return {null}
 */
export default function Screen(props){
    if(props.loadingUser){
        return (
            <div
                className={"loader-background"}
                style={props.style}
            >
                <div className="centered-div">
                    <Spinner width={100} height={100}/>
                </div>
            </div>
        );
    }else if(props.loadingProcess){
        return (
            <div
                className={"loader-background-transparent"}
                style={props.style}
            >
                <div className="centered-div">
                    <Spinner width={100} height={100}/>
                </div>
            </div>
        );
    }else{
        return null;
    }
}
