import React, {useState} from "react";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {createListArray} from "../../modules/functions";
import Button from "../Form/Button";
import Badge from "@material-ui/core/Badge";

export default props => {
    const {index, item, deleteItem, settings, editItem} = props;
    const [submenu, showSubmenu] = useState(false);
    return (
        <li className={index % 2 ? "even" : "uneven"} onClick={() => showSubmenu(o => !o)}>
            <div className={"guest"}>
                <span>
                    <span className={"guest-name"}>
                        {item.name}
                    </span>
                    {
                        (item.attends && item.numberGuests > 0) && (
                            ` +${item.numberGuests} weitere Gäste:`
                        )
                    }
                </span>
                {
                    (settings.signup.customTextfield && item.customText) ? (
                        <Badge badgeContent={1} color="secondary">
                            {
                                item.attends ? (
                                    <CheckIcon color={"primary"} fontSize={"large"}/>
                                ) : (
                                    <CloseIcon color={"error"} fontSize={"large"}/>
                                )
                            }
                        </Badge>
                    ) : item.attends ? (
                        <CheckIcon color={"primary"} fontSize={"large"}/>
                    ) : (
                        <CloseIcon color={"error"} fontSize={"large"}/>
                    )
                }
            </div>
            {
                (item.attends && item.numberGuests > 0) && (
                    <div>
                        {
                            createListArray(item.guests).map(
                                (item, index, array) => (<span key={index}>{item.name + (index < array.length - 1 ? ", " : "")}</span>)
                            )
                        }
                    </div>
                )
            }
            {
                // More Info --> Food
                (submenu && settings.signup.foodPreferences && item.attends) && (
                    <ul className={"simpleList bigBorder spacer"} style={{marginTop: 20}}>
                        <li className={"space-horizontal align-vertical-mobile"} style={{padding: 10}}>
                            <strong>
                                {item.name}
                            </strong>
                            <span className={"value-preview"}>
                                {settings.signup.foodOptions[item.foodOption]}
                            </span>
                        </li>
                        {
                            createListArray(item.guests).map(
                                (guest, i, a) => (
                                    <li
                                        key={i}
                                        className={"space-horizontal align-vertical-mobile"} style={{padding: 10}}
                                    >
                                        <strong>
                                            {guest.name}
                                        </strong>
                                        <span className={"value-preview"}>
                                            {guest.foodOption ? settings.signup.foodOptions[guest.foodOption] : settings.signup.foodOptions[0]}
                                        </span>
                                    </li>
                                )
                            )
                        }
                    </ul>
                )
            }
            {
                // More Info --> Custom Text
                (submenu && settings.signup.customTextfield && item.customText) && (
                    <div style={{margin: "20px 0px"}}>
                        <strong>Nachricht</strong>
                        <blockquote >
                            {item.customText}
                        </blockquote>
                    </div>
                )
            }
            {
                // Submenu
                submenu && (
                    <div className={"align-horizontal align-vertical-mobile"} style={{marginTop: 20}}>
                        <Button
                            color={"secondary"}
                            type="button"
                            variant={"contained"}
                            size={"small"}
                            onClick={() => window.open("mailto:" + item.email)}
                            className={"margin-item"}
                        >
                            Kontaktieren
                        </Button>
                        <Button
                            color={"primary"}
                            type="button"
                            variant={"contained"}
                            size={"small"}
                            onClick={editItem}
                            className={"margin-item"}
                        >
                            Bearbeiten
                        </Button>
                        <Button
                            color={"error"}
                            type="button"
                            variant={"contained"}
                            size={"small"}
                            onClick={deleteItem}
                            className={"margin-item"}
                        >
                            Löschen
                        </Button>
                    </div>
                )
            }

        </li>
    )
}
