import React from 'react';
import Button from '../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "./Transition";
import SignupForm from "../Signup/Form";
import {guestFormValues} from "../../views/internal/profile";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

export default function EditSignupForm(props) {
    const {open, callback, fail, guestData, settings, setLoading, isAdmin} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={fail}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullScreen={fullScreen}
        >
            <DialogTitle id="alert-dialog-slide-title">Eintrag bearbeiten</DialogTitle>
            <DialogContent>
                {
                    guestData && (
                        <SignupForm
                            uid={guestData.id}
                            isAdmin={isAdmin}
                            defaultValues={guestFormValues(guestData)}
                            setLoading={setLoading}
                            callback={callback}
                            settings={settings}
                            style={{marginTop: 0}}
                        />
                    )
                }
                <div style={{padding: "0 20px"}}>
                    <Button onClick={fail} color="info" size={"large"} fullWidth>
                        Abbrechen
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
