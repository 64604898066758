import React from "react";
import "./header.css";
import LogoWhite from "../../images/logo-white.png";
import {useHistory} from "react-router-dom";
import NavItem from "./NavItem";
import {useMobileListener} from "../../hooks/layout";

export default function Header(props){
    const history = useHistory();
    const isMobile = useMobileListener();
    const isAdmin = props.user && props.user.data && props.user.data.isAdmin;
    return (
        <nav className={"header"}>
            <div onClick={() => history.push("/internal/home")} className={"logo"}>
                <img
                    src={LogoWhite}
                    alt={"logo"}
                />
            </div>
            {
                isAdmin ? (
                    <div className={"nav"}>
                        <NavItem
                            path={"/admin/settings"}
                        >
                            Einstellungen
                        </NavItem>
                        <NavItem path={"/admin/guests"}>
                            Zusagen
                        </NavItem>
                        {
                            (props.settings && props.settings.gallery && props.settings.gallery.active && props.settings.gallery.startDate < (new Date()).getTime()) && (
                                <NavItem path={"/internal/photos"}>
                                    Photos
                                </NavItem>
                            )
                        }
                        <NavItem path={"/admin/messages"}>
                            Nachrichten
                        </NavItem>
                    </div>
                ) : (
                    <div className={"nav"}>
                        <NavItem
                            path={"/internal/home"}
                        >
                            Info
                        </NavItem>
                        <NavItem path={"/internal/profile"}>
                            Meine Zusage
                        </NavItem>
                        {
                            (props.settings && props.settings.gallery && props.settings.gallery.active && props.settings.gallery.startDate < (new Date()).getTime()) && (
                                <NavItem path={"/internal/photos"}>
                                    Photos
                                </NavItem>
                            )
                        }
                        <NavItem path={"/internal/messages"}>
                            Nachrichten
                        </NavItem>
                    </div>
                )
            }

        </nav>
    )
}
