import React from "react";
import TimelineItem from "./Item";
import "./index.css";

export default function TimelinePage(props){
    const {item, index} = props;
    return (
        <>
            <div style={{backgroundColor: "var(--backgroundSecondaryLight)"}}>
                <div className={"center-text content-box"} style={{paddingBottom: 40}}>
                    <h1>{item.timeline.title}</h1>
                    <span>{item.timeline.text}</span>
                </div>
            </div>
            <div className={`big-content-box timeline ${index % 2 ? "even" : ""}`}>
                <div className={"timeline-line"} />
                {
                    item.timeline.items.map(
                        (item, index) => (
                            <TimelineItem index={index} key={index} item={item} />
                        )
                    )
                }
            </div>
        </>
    );
}
