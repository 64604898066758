import React from "react";
import "./item.css";
import PlaceIcon from '@material-ui/icons/Place';

export default function RoomItem(props){
    const {item} = props;
    const googleLink = "https://www.google.com/maps/place/" + item.address.replace(/[ \n]+/g, "+");
    return(
        <div className={"room-item"}>
            <div className={"room-preview"} onClick={() => window.open(googleLink, "_blank")}>
                <img
                    src={item.image.url}
                />
                <div className={"image-overlay"}>
                    <PlaceIcon
                        color={"var(--background)"}
                    />
                </div>
            </div>
            <div className={"room-details"}>
                <h3>{item.title}</h3>
                <div style={{marginTop: 20, marginBottom: 20}}>{item.text}</div>
                <div className={"room-details-item"}><strong>Addresse:</strong> <a href={googleLink} target={"_blank"}>{item.address}</a></div>
                {
                    item.website && (<div className={"room-details-item"}><strong>Webseite:</strong> <a href={item.website} target={"_blank"}>{item.website}</a></div>)
                }
                {
                    item.telephone && (<div className={"room-details-item"}><strong>Telefon:</strong> <a href={"tel:" + item.telephone} target={"_blank"}>{item.telephone}</a></div>)
                }
            </div>
        </div>
    );
}
