import React from "react";

export const waitForEach = (a, fn) => new Promise( (resolve, reject) => {
    if(a.length === 0){
        resolve();
    }else{
        try {
            let count = a.length;
            a.forEach(async (value, index) => {
                await fn(value, index);
                count--;
                if (count === 0) resolve();
            });
        }catch (e) {
            reject(e);
        }
    }
});

export const createListArray = (lists = {}) => {
    return Object.keys(lists).map(
        listKey => ({
            id: listKey,
            ...lists[listKey]
        })
    )
};

export const createGuestListArray = (lists = {}) => {
    let countGuests = 0;
    let countRegistrations = 0;
    let array = Object.keys(lists).map(
        listKey => {
            countRegistrations++;
            if(lists[listKey].attends){
                countGuests = countGuests + 1 + parseInt(lists[listKey].numberGuests);
            }
            return ({
                id: listKey,
                ...lists[listKey]
            });
        }
    );
    return({
        array,
        countGuests,
        countRegistrations
    });
};

export const createMessagesArray = (messages = {}) => {
    return createListArray(messages).sort((a, b) => a.time > b.time ? 1 : -1);
};

export const createID = () => Math.random().toString(36).substr(2, 12);

export const existsOrDefault = (val, defaultVal) => val ? val : defaultVal;
