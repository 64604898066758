import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAwi3oK45x9_N3n2K5Q7CQGKL9OT76V41Y",
    authDomain: "alineundfabi.firebaseapp.com",
    databaseURL: "https://alineundfabi-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "alineundfabi",
    storageBucket: "alineundfabi.appspot.com",
    messagingSenderId: "18382977651",
    appId: "1:18382977651:web:ce6976ba6bcd217d09c806"
};

let fire = firebase.initializeApp(firebaseConfig);

export {firebase};
export default fire;
