import React from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

export default props => {
    const {label, options, value, onChange} = props;
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup value={"" + value} onChange={onChange}>
                {
                    options.map(
                        (item, index) => (
                            <FormControlLabel key={index} value={"" + item.value} control={<Radio />} label={item.label} />
                        )
                    )
                }
            </RadioGroup>
        </FormControl>
    );
}
