import fire, {firebase} from "../modules/firebase";
import {createID} from "../modules/functions";
import {toBase64} from "../modules/image";

export const uploadImages = (files, user, setProgress, album = "all") => new Promise(async (resolve, reject) => {
    let progress = Array(files.length).fill(0);
    try{
        // Upload Files
        const results = await Promise.all(files.map((file, index) => uploadImage(file, user, (prog) => {
            progress[index] = prog;
            const sum = progress.reduce((a, b) => a + b, 0);
            const avg = (sum / progress.length) || 0;
            setProgress(avg);
        })));
        // Writing in Database
        const update = {};
        results.forEach(
            r => {
                update[r.id] = {
                    ...r,
                    uploaded: firebase.database.ServerValue.TIMESTAMP,
                    user: user.data.name
                };
                if(album !== "all"){
                    update[r.id].albums = {
                        [album]: true
                    }
                }
            }
        );
        await fire.database().ref(`photos/${user.uid}/`).update(update);
        resolve(results);
    }catch (e) {
        reject(e);
    }
});

export const createThumbnail = file => new Promise(async (resolve, reject) => {
    try{
        let image, oldWidth, oldHeight, newHeight, newWidth, canvas, ctx, newDataUrl;
        // Create a temporary image so that we can compute the height of the downscaled image.
        image = new Image();
        image.src = await toBase64(file);
        image.onload = () => {
            oldWidth = image.width;
            oldHeight = image.height;
            const maxSize = 1000;
            if(oldHeight > oldWidth){
                newHeight = Math.floor(maxSize);
                newWidth = Math.floor(maxSize*oldWidth/oldHeight);
            }else{
                newHeight = Math.floor(maxSize*oldHeight/oldWidth);
                newWidth = Math.floor(maxSize);
            }
            // Create a temporary canvas to draw the downscaled image on.
            canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the downscaled image on the canvas and return the new data URL.
            ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, newWidth, newHeight);
            newDataUrl = canvas.toDataURL(file.type);
            resolve(newDataUrl);
        }
    }catch (e) {
        reject(e);
    }
});

export const uploadImage = (file, user, setProgress) => new Promise(async (resolve, reject) => {
    try{
        const path = `photos/${user.uid}/`;
        let fileName;
        const id = createID();
        switch (file.type) {
            case "image/png":
                fileName = id + '.png';
                break;
            case "image/jpg":
                fileName = id + '.jpg';
                break;
            case "image/jpeg":
                fileName = id + '.jpeg';
                break;
            case "image/svg":
                fileName = id + '.svg';
                break;
            case "image/gif":
                fileName = id + '.gif';
                break;
            default:
                fileName = id + '.jpg';
        }
        const ref = fire.storage().ref(path + fileName);
        const uploadTask = ref.put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(prog);
            }, function(error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        reject("Nicht authorisiert. Gehe sicher dass du angemeldet bist.");
                        break;

                    case 'storage/canceled':
                        reject("Upload vom Nutzer abgebrochen.");
                        break;

                    case 'storage/unknown':
                    default:
                        reject(error.message);
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            }, async function() {
                try{
                    const thumbnail = await createThumbnail(file);
                    const thumbnailURI = path + "thumbnail." + fileName;
                    const refThumb = fire.storage().ref(thumbnailURI);
                    await refThumb.putString(thumbnail, 'data_url');
                    const urlThumb = await refThumb.getDownloadURL();
                    const url = await ref.getDownloadURL();
                    let img = new Image();
                    img.onload = () => {
                        resolve({
                            thumb: {
                                uri: thumbnailURI,
                                url: urlThumb
                            },
                            id: id,
                            uri: path + fileName,
                            url: url,
                            fileModified: file.lastModified,
                            width: img.width,
                            height: img.height,
                            data: {link: url}
                        });
                    };
                    img.src = url;
                }catch (e) {
                    reject(e.message);
                }
            }
        );
    }catch (e) {
        reject(e.message);
    }
});

export const deletePhoto = imageObj => new Promise(async (resolve, reject) => {
    try{
        if(imageObj.thumb){
            await fire.storage().ref(imageObj.thumb.uri).delete();
        }
        await fire.storage().ref(imageObj.uri).delete();
        await fire.database().ref(`photos/${imageObj.uid}/${imageObj.id}`).remove();
        resolve();
    }catch (e) {
        reject(e);
    }
});

export const deletePhotos = array => Promise.all(array.map(imageObj => deletePhoto(imageObj)));

export const movePhotoToAlbum = (imageObj, albumsObj) => new Promise(async (resolve, reject) => {
    try{
        await fire.database().ref(`photos/${imageObj.uid}/${imageObj.id}/albums`).set(albumsObj);
        resolve();
    }catch (e) {
        reject(e);
    }
});
export const movePhotosToAlbums = (arrayPhotos, albumsObj) => Promise.all(arrayPhotos.map(imageObj => movePhotoToAlbum(imageObj, albumsObj)));

export const setFavorite = (imageObj, value) => {
    fire.database().ref(`photos/${imageObj.uid}/${imageObj.id}`).update({
        favorite: value
    }).catch(e => console.error(e));
};

export const setAlbums = albums => (
    fire.firestore().collection("admin").doc("settings").update({
        ["gallery.albums"]: albums
    })
);

export const getNumberOfPhotosFromAlbum = albumID => (
    new Promise(async (resolve, reject) => {
        try{
            await fire.database().ref(`photos`).once('value').then((snapshot) => {
                const photos = snapshot.val();
                if(!photos){
                    reject();
                }else{
                    let count = 0;
                    Object.keys(photos).forEach(
                        userKey => {
                            Object.keys(photos[userKey]).forEach(
                                photoKey => {
                                    if(photos[userKey][photoKey] && photos[userKey][photoKey].albums && photos[userKey][photoKey].albums[albumID]){
                                        count++;
                                    }
                                }
                            )
                        }
                    );
                    resolve(count);
                }
            });
        }catch (e) {
            reject(e);
        }
    })
);
