import React, {useState} from "react";
import DatePicker from "../../components/Form/DatePicker";
import {changeGallerySettings} from "../../actions/settings";
import Button from "../../components/Form/Button";
import AlbumsDialog from "./AlbumsDialog";

export default function PhotosSettings(props) {
    const {settings, setSettings, setLoading} = props;
    const [dialog, setDialog] = useState(false);
    return (
        <div>
            <AlbumsDialog
                open={dialog}
                close={() => setDialog(false)}
                settings={settings}
                setLoading={setLoading}
                setSettings={setSettings}
            />
            <div className={"center-text content-box"}>
                <h2>Gäste Fotogalerie</h2>
                <p>
                    Ermögliche deinen Gästen ihre Fotos mit euch und anderen Gästen zu teilen!
                </p>
                <div className={"center-vertical"}>
                    <DatePicker
                        timestampValue={settings.gallery && settings.gallery.startDate ? settings.gallery.startDate : (new Date()).getTime()}
                        onChange={e => {
                            const update = {
                                active: true,
                                startDate: e.getTime()
                            };
                            changeGallerySettings(update);
                            setSettings(o => ({...o, gallery: update}));
                        }}
                        buttonStyle={{marginTop: 0}}
                        TextFieldComponent={
                            ({value, onClick}) => (
                                <Button
                                    size={"large"}
                                    onClick={onClick}
                                    color={settings.gallery && settings.gallery.active ? "secondary" : "primary"}
                                    round
                                    variant={settings.gallery && settings.gallery.active ? "outlined" : "contained"}
                                    style={{marginTop: 20}}
                                >
                                    {
                                        settings.gallery && settings.gallery.active ? (
                                            `Aktiviert zum ${value}`
                                        ) : (
                                            `Aktivieren zum Zeitpunkt`
                                        )
                                    }
                                </Button>
                            )
                        }
                    />
                    {
                        (settings.gallery && settings.gallery.active) && (
                            <Button
                                size={"large"}
                                onClick={()=>{
                                    changeGallerySettings({active: false});
                                    setSettings(o => ({...o, gallery: {...o.gallery, active: false}}));
                                }}
                                color="danger"
                                round
                                variant={ "text"}
                                style={{marginTop: 20}}
                            >
                                Deaktivieren
                            </Button>
                        )
                    }
                    {
                        (settings.gallery && settings.gallery.active) && (
                            <>
                                <h3>Du möchtest mehrere Galerien?</h3>
                                <p>Dann erstelle einfach mehrere Alben! Du kannst dabei für jedes Album einstellen, ob nur das Brautpaar oder auch die Gäste Fotos hinzufügen können.</p>
                                <Button
                                    size={"large"}
                                    onClick={() => setDialog(true)}
                                    color={"primary"}
                                    round
                                    variant={"outlined"}
                                    style={{marginTop: 20}}
                                >
                                    Alben bearbeiten
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
