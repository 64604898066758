import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const htmlToEditorState = html => {
    const { contentBlocks, entityMap } = htmlToDraft(html);
    return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap));
};

export default forwardRef((props, ref) => {
    const {onChange, defaultValue, ...p} = props;
    const [state, editState] = useState(defaultValue
        ? htmlToEditorState(defaultValue)
        : EditorState.createEmpty()
    );
    useEffect(
        () => {
            if(onChange){
                onChange(draftToHtml(convertToRaw(state.getCurrentContent())));
            }
        },
        [state]
    );
    useImperativeHandle(ref, () => ({
        getValue(){
            if(state.getCurrentContent().getPlainText().replace(/\n/g, "").replace(/\s/g, "") === ""){
                // Empty Field
                return false;
            }else{
                return draftToHtml(convertToRaw(state.getCurrentContent()));
            }
        },
        clearValue(){
            editState(EditorState.createEmpty())
        },
        setValue(v){
            editState(htmlToEditorState(v))
        },
        resetValue(){
            editState(defaultValue ? htmlToEditorState(defaultValue) : EditorState.createEmpty())
        }
    }));
    return (
        <Editor
            editorState={state}
            wrapperClassName="editor-wrapper"
            editorClassName="editor"
            onEditorStateChange={editState}
            {...p}
        />
    );
});
