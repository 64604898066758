import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "../../Dialogs/Transition";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Input from "../../Form/Input";
import {editPages} from "../../../actions/pages";
import addDate from "date-fns/add";
import DatePicker from "../../Form/DatePicker";
import TimePicker from "../../Form/TimePicker";
import Checkbox from "../../Form/Checkbox";


export default function EditCountdownDialog(props) {
    const {open, callback, close, pageType, pages, setPages, item, setLoading} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState({
        ...item,
        countdown: {
            date: addDate(new Date(), {months: 3}).getTime(),
            countHoursMinutes: true,
            title: "Countdown"
        }
    });
    useEffect(
        () => {
            setForm(o => ({
                ...o,
                ...item
            }))
        },
        [item]
    );
    const [message, setMessage] = useState(props.message ? props.message : null);
    const [error, setError] = useState({});
    const save = useCallback(
        async (form) => {
            try{
                setLoading(true);
                const index = pages.findIndex(i => i.id === form.id);
                const {pageType, ...r} = form;
                const newItem = {
                    ...r,
                    type: "countdown"
                };
                if(index > -1){
                    // Edit
                    pages[index] = newItem;
                }else{
                    // New
                    pages.push(newItem);
                }
                const newPages = await editPages(pages, pageType);
                setPages(newPages);
                setLoading(false);
                close();
            }catch (e) {
                console.log(e);
                setLoading(false);
                if(e.info){
                    setMessage(e.info);
                }else{
                    setError(typeof e === 'string' ? e : e.message);
                }
            }
        },
        [pages, pageType]
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title">Countdown hinzufügen</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <form>
                        {
                            message && (<div className={"panel"}>{message}</div>)
                        }
                        <Input
                            multiline
                            value={form.countdown.title}
                            labelText={"Seitentitel"}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            handleChange={(e)=>{
                                setForm(o => ({
                                    ...o,
                                    countdown: {
                                        ...o.countdown,
                                        title: e.target.value
                                    }
                                }));
                            }}
                            error={error.title}
                        />
                        <Checkbox
                            label={"Mit Stunden und Minuten"}
                            checked={form.countdown.countHoursMinutes}
                            handleChange={e => {
                                setForm(o => ({
                                    ...o,
                                    countdown: {
                                        ...o.countdown,
                                        countHoursMinutes: e.target.checked
                                    }
                                }));
                            }}
                        />
                        <div className={"simpleBorderArea align-horizontal align-vertical-mobile"}>
                            <div className={"align-vertical margin-item"}>
                                <label>
                                    Datum
                                </label>
                                <DatePicker
                                    timestampValue={form.countdown.date}
                                    onChange={e => {
                                        setForm(o => ({
                                            ...o,
                                            countdown: {
                                                ...o.countdown,
                                                date: e.getTime()
                                            }
                                        }));
                                    }}
                                    buttonStyle={{marginTop: 0}}
                                />
                            </div>
                            {
                                form.countdown.countHoursMinutes && (
                                    <div className={"align-vertical"}>
                                        <label>
                                            Zeit
                                        </label>
                                        <TimePicker
                                            timestampValue={form.countdown.date}
                                            onChange={e => {
                                                setForm(o => ({
                                                    ...o,
                                                    countdown: {
                                                        ...o.countdown,
                                                        date: e.getTime()
                                                    }
                                                }));
                                            }}
                                            buttonStyle={{marginTop: 0}}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="info">
                    Abbrechen
                </Button>
                <Button
                    onClick={async () => {
                        await save(form);
                        if(callback){
                            callback();
                        }
                    }}
                    color="primary"
                >
                    {pages.findIndex(i => i.id === form.id) > -1 ? "Speichern" : "Hinzufügen"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
