import React, {useEffect, useState} from "react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import "./contentPreview.css";
import {useMeasurePosition} from "../../hooks/animation";
import { motion } from "framer-motion";
import LocationsPage from "../Pages/Locations";
import CountdownPage from "../Pages/Countdown";
import TimelinePage from "../Pages/Timeline";
import RoomsPage from "../Pages/Rooms";
import PersonIntroPage from "../Pages/PersonIntro";

export const ContentPreviewInnerContent = props => {
    const {item, edit, index} = props;
    return (
        <div
            className={"content-preview"}
            style={{backgroundColor: index % 2 ? "var(--backgroundPrimaryLight)" : "var(--background)"}}

        >
            {
                item.type === "html" ? (
                    <div className={"html-preview"} dangerouslySetInnerHTML={{__html: item.html}} />
                ) : item.type === "image" ? (
                    <div className={"image-preview"}>
                        <img
                            className={"flyer"}
                            src={item.image.url}
                            alt={"flyer"}
                        />
                    </div>
                ) : item.type === "signup" ? (
                    <div className={"signup-preview"}>
                        <h3>Registrierung</h3>
                    </div>
                ) : item.type === "locations" ? (
                    <div className={"locations-preview"}>
                        <LocationsPage item={item}/>
                    </div>
                ) : item.type === "personIntro" ? (
                    <div className={"personIntro-preview"}>
                        <PersonIntroPage item={item}/>
                    </div>
                ) : item.type === "countdown" ? (
                    <div className={"countdown-preview"}>
                        <CountdownPage item={item}/>
                    </div>
                ) : item.type === "timeline" ? (
                    <div className={"timeline-preview"}>
                        <TimelinePage index={index} item={item}/>
                    </div>
                ) : item.type === "rooms" ? (
                    <div className={"rooms-preview"}>
                        <RoomsPage item={item}/>
                    </div>
                ) : null
            }
            {
                edit && <div className={"overlay"} />
            }
        </div>
    );
}

export default function ContentPreview(props){
    const {index, item, updatePosition, updateOrder, setDeletePage, edit} = props;
    const [isDragging, setDragging] = useState(false);
    const [contextMenu, setContextMenu] = useState(false);
    const ref = useMeasurePosition((pos) => updatePosition(index, pos));
    return(
        <motion.div
            ref={ref}
            layout
            initial={false}
            style={{position: "relative", cursor: "move", zIndex: isDragging ? 10 : 1}}
            onMouseEnter={() => setContextMenu(true)}
            onMouseLeave={() => setContextMenu(false)}
            onContextMenu={() => setContextMenu(o => !o)}
            whileHover={{
                scale: 1.03,
            }}
            whileTap={{
                scale: 1.12,
            }}
            drag="y"
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
            onViewportBoxUpdate={(_viewportBox, delta) => {
                isDragging && updateOrder(index, delta.y.translate);
            }}
        >
            <ContentPreviewInnerContent
                item={item}
                edit={contextMenu}
                index={index}
            />
            {
                contextMenu && (
                    <div className={"content-context-menu"}>
                        <IconButton
                            aria-label="edit"
                            onClick={edit}
                        >
                            <EditIcon color={"primary"}/>
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={setDeletePage}
                        >
                            <DeleteIcon color={"error"}/>
                        </IconButton>
                    </div>
                )
            }
        </motion.div>
    )

}
