export const ICON_LIST = [
    {
        value: "icon_balloons_01.svg"
    },
    {
        value: "icon_balloons_02.svg"
    },
    {
        value: "icon_balloons_03.svg"
    },
    {
        value: "icon_balloons_04.svg"
    },
    {
        value: "icon_cake_01.svg"
    },
    {
        value: "icon_cake_02.svg"
    },
    {
        value: "icon_cake_03.svg"
    },
    {
        value: "icon_cake_04.svg"
    },
    {
        value: "icon_ceremony_01.svg"
    },
    {
        value: "icon_ceremony_02.svg"
    },
    {
        value: "icon_ceremony_03.svg"
    },
    {
        value: "icon_ceremony_04.svg"
    },
    {
        value: "icon_ceremony_05.svg"
    },
    {
        value: "icon_church_01.svg"
    },
    {
        value: "icon_church_02.svg"
    },
    {
        value: "icon_church_03.svg"
    },
    {
        value: "icon_church_04.svg"
    },
    {
        value: "icon_church_05.svg"
    },
    {
        value: "icon_church_06.svg"
    },
    {
        value: "icon_church_07.svg"
    },
    {
        value: "icon_dancing_01.svg"
    },
    {
        value: "icon_dancing_02.svg"
    },
    {
        value: "icon_dancing_03.svg"
    },
    {
        value: "icon_dancing_01.svg"
    },
    {
        value: "icon_end_01.svg"
    },
    {
        value: "icon_end_02.svg"
    },
    {
        value: "icon_end_03.svg"
    },
    {
        value: "icon_end_04.svg"
    },
    {
        value: "icon_end_05.svg"
    },
    {
        value: "icon_end_06.svg"
    },
    {
        value: "icon_end_07.svg"
    },
    {
        value: "icon_firework_1.svg"
    },
    {
        value: "icon_firework_2.svg"
    },
    {
        value: "icon_food_01.svg"
    },
    {
        value: "icon_food_02.svg"
    },
    {
        value: "icon_food_03.svg"
    },
    {
        value: "icon_food_04.svg"
    },
    {
        value: "icon_gift_01.svg"
    },
    {
        value: "icon_gift_02.svg"
    },
    {
        value: "icon_gift_03.svg"
    },
    {
        value: "icon_gift_04.svg"
    },
    {
        value: "icon_gift_05.svg"
    },
    {
        value: "icon_images_1.svg"
    },
    {
        value: "icon_images_2.svg"
    },
    {
        value: "icon_images_3.svg"
    },
    {
        value: "icon_images_4.svg"
    },
    {
        value: "icon_love_01.svg"
    },
    {
        value: "icon_love_02.svg"
    },
    {
        value: "icon_love_03.svg"
    },
    {
        value: "icon_love_04.svg"
    },
    {
        value: "icon_love_05.svg"
    },
    {
        value: "icon_noneymoon_01.svg"
    },
    {
        value: "icon_noneymoon_02.svg"
    },
    {
        value: "icon_noneymoon_03.svg"
    },
    {
        value: "icon_noneymoon_04.svg"
    },
    {
        value: "icon_noneymoon_05.svg"
    },
    {
        value: "icon_noneymoon_06.svg"
    },
    {
        value: "icon_noneymoon_07.svg"
    },
    {
        value: "icon_noneymoon_08.svg"
    },
    {
        value: "icon_noneymoon_09.svg"
    },
    {
        value: "icon_noneymoon_10.svg"
    },
    {
        value: "icon_noneymoon_11.svg"
    },
    {
        value: "icon_noneymoon_12.svg"
    },
    {
        value: "icon_outfit_01.svg"
    },
    {
        value: "icon_outfit_02.svg"
    },
    {
        value: "icon_outfit_03.svg"
    },
    {
        value: "icon_outfit_04.svg"
    },
    {
        value: "icon_party_01.svg"
    },
    {
        value: "icon_party_02.svg"
    },
    {
        value: "icon_party_03.svg"
    },
    {
        value: "icon_party_04.svg"
    },
    {
        value: "icon_snack_01.svg"
    },
    {
        value: "icon_surprise_01.svg"
    },
    {
        value: "icon_surprise_02.svg"
    },
    {
        value: "icon_surprise_03.svg"
    },
    {
        value: "icon_surprise_04.svg"
    },
    {
        value: "icon_surprise_05.svg"
    },
    {
        value: "icon_surprise_06.svg"
    },
    {
        value: "icon_surprise_07.svg"
    },
    {
        value: "icon_wedding_01.svg"
    },
    {
        value: "icon_wedding_02.svg"
    },
    {
        value: "icon_wedding_03.svg"
    },
    {
        value: "icon_wedding_04.svg"
    },
    {
        value: "icon_wine_01.svg"
    },
    {
        value: "icon_wine_02.svg"
    },
    {
        value: "icon_wine_03.svg"
    },
    {
        value: "icon_wine_04.svg"
    },
    {
        value: "icon_wine_05.svg"
    },
    {
        value: "icon_wine_06.svg"
    },
    {
        value: "icon_wine_07.svg"
    }
];
