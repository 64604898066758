import React, {useCallback} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

export default function CustomCheckbox(props){
    const {
        formControlProps,
        labelText,
        handleChange,
        checked,
        setForm,
        id,
        ...rest
    } = props;
    const direct_handleChange = useCallback(e => {
        if(setForm){
            setForm(f => ({
                ...f,
                [id]: e.target.checked
            }));
        }else{
            console.error("Need to pass setForm");
        }
    }, [id, setForm]);
    return (
        <FormGroup {...formControlProps}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!checked}
                        onChange={handleChange ? handleChange : direct_handleChange}
                        id={id}
                    />
                }
                label={labelText}
                {...rest}
            />
        </FormGroup>
    );
}
