import React, {useEffect, useState} from "react";
import Button from "../Form/Button";
import "./iconPicker.css";
import Paper from "@material-ui/core/Paper";
import WeddingIcon from "../Icons/WeddingIcon";

export default props => {
    const {value, onChange, buttonStyle, icons, children, ...p} = props;
    const [open, setOpen] = useState(false);

    return (
        <div className={"icon-picker"} style={{marginTop: 20}}>
            <div
                className={"icon-button"}
                onClick={() => setOpen(o => !o)}
            >
                <WeddingIcon name={value} white />
            </div>
            <div className={`content ${open ? "open" : ""}`}>
                {
                    open ? (
                        <div className={"icon-list"}>
                            {
                                icons.map(
                                    (item, index) => {
                                        return (
                                            <div
                                                className={"icon-item"}
                                            >
                                                <WeddingIcon
                                                    name={item.value}
                                                    key={index}
                                                    onClick={() => {
                                                        onChange(item);
                                                        setOpen(false);
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                )
                            }
                        </div>
                    ) : children
                }
            </div>
        </div>
    );
};
