import React, {useEffect, useState} from "react";
import Transition from "../Dialogs/Transition";
import Button from "../../components/Form/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {usePositionReorder} from "../../hooks/animation";
import {createListArray} from "../../modules/functions";
import AlbumItem from "./AlbumItem";
import EditAlbum from "./EditAlbum";
import {setAlbums} from "../../actions/photos";
import GroupItem from "../Messages";
import DeleteDialog from "../Dialogs/Delete";

export default function AlbumsDialog(props) {
    const {open, callback, close, setLoading, settings, setSettings} = props;
    const [form, setForm] = useState({
        albums: settings.gallery && settings.gallery.albums ? createListArray(settings.gallery.albums).sort((a, b) => a.index >= b.index ? 1 : -1) : []
    });
    const [order, updatePosition, updateOrder, reset] = usePositionReorder(form.albums);
    const [updated, setUpdated] = useState(false);
    const [orderChanged, setOrderChange] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [numberOfPhotos, setNumberOfPhotos] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(
        () => {
            let count = order.length;
            if(order.length !== form.albums.length){
                reset();
            }else{
                order.forEach((i, index) => {
                    if(i.id !== form.albums[index].id){
                        setForm({albums: order});
                        setOrderChange(true);
                    }else{
                        count--;
                        if(count === 0){
                            setOrderChange(false);
                        }
                    }
                });
            }

        },
        [order, form]
    );
    useEffect(
        () => {
            setUpdated(false);
        },
        [updated]
    );
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullScreen={fullScreen}
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">Photo Alben bearbeiten</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <form>
                            <div className={"simpleBorderArea align-vertical"}>
                                <h3>Neues Album erstellen</h3>
                                <EditAlbum
                                    save={i => {
                                        setForm(f => ({
                                            ...f,
                                            albums: [...f.albums, i]
                                        }))
                                    }}
                                    close={() =>null}
                                    style={{  width: "100%"}}
                                />
                            </div>
                            <div className={"simpleBorderArea align-vertical"}>
                                {
                                    order.length < 1 ? (
                                        <p>Bisher wurden keine Alben erstellt. Alle Fotos werden auf einer Seite dargestellt.</p>
                                    ) : order.map(
                                        (item, index) => (
                                            <AlbumItem
                                                index={index}
                                                item={item} key={item.id} setForm={setForm}
                                                updateOrder={updateOrder} updatePosition={updatePosition}
                                                setUpdated={setUpdated}
                                                setDelete={(item, numberOfPhotos) => {
                                                    setDeleteItem({...item, numberOfPhotos});
                                                }}
                                            />
                                        )
                                    )
                                }
                            </div>
                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={
                            () => {
                                setForm({albums: settings.gallery && settings.gallery.albums ? createListArray(settings.gallery.albums).sort((a, b) => a.index >= b.index ? 1 : -1) : []});
                                close();
                            }
                        }
                        color="info"
                    >
                        Abbrechen
                    </Button>
                    <Button
                        onClick={async () => {
                            try{
                                setLoading(true);
                                const albums = {};
                                form.albums.forEach(
                                    (item, index) => {
                                        const {id, ...i} = item;
                                        albums[id] = {
                                            ...i,
                                            index: index
                                        };
                                    }
                                );
                                await setAlbums(albums);
                                setSettings(s => (
                                    {...s, gallery:{
                                        ...(s.gallery ? s.gallery : {}),
                                        albums: albums
                                    }}
                                ));
                                setLoading(false);
                                close();
                            }catch (e) {
                                console.error(e);
                                setLoading(false);
                            }
                        }}
                        color="primary"
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
            <DeleteDialog
                open={!!deleteItem}
                fail={() => setDeleteItem(false)}
                success={ () => {
                    setForm(f => {
                        const albums = f.albums.filter(i => i.id !== deleteItem.id);
                        return {albums};
                    });
                    setDeleteItem(false);
                }}
                item={deleteItem && (
                    <>
                        <div style={{width: "fit-content"}}>
                            <AlbumItem
                                onlyDisplay
                                index={0}
                                item={deleteItem} setForm={setForm}
                                updateOrder={updateOrder} updatePosition={updatePosition}
                                setUpdated={setUpdated}
                            />
                        </div>
                        Um ein Album erfolgreich zu löschen musst du im nächsten Dialog die neue Liste von Alben speichern.
                    </>
                )}
            />
        </>
    );
}
