import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "../../Dialogs/Transition";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Input from "../../Form/Input";
import {editPages, uploadImage} from "../../../actions/pages";
import ImageIcon from '@material-ui/icons/Image';
import {createID} from "../../../modules/functions";
import ImageCropperDialog from "../../Dialogs/CropProfileImage";
import fire from "../../../modules/firebase";

const emptyItem = () => ({
    id: createID(),
    name: "",
    tagLine: "",
    text: "",
    email: "",
    telephone: "",
    image: {}
});


export default function EditPersonIntroductionDialog(props) {
    const {open, callback, close, pageType, pages, setPages, item, setLoading} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [form, setForm] = useState({
        ...item,
        personIntro: {
            items: [],
            title: "Die Trauzeugen",
            text: "Bitte wendet euch an unsere Trauzeugen für Anfragen aller Art."
        }
    });
    useEffect(
        () => {
            setForm(o => ({
                ...o,
                ...item
            }))
        },
        [item]
    );
    const [editImage, setEditImage] = useState(null);
    const [message, setMessage] = useState(props.message ? props.message : null);
    const [error, setError] = useState({});
    const save = useCallback(
        async (form) => {
            try{
                setLoading(true);
                const index = pages.findIndex(i => i.id === form.id);
                const {pageType, ...r} = form;
                const newItem = {
                    ...r,
                    type: "personIntro"
                };
                if(index > -1){
                    // Edit
                    pages[index] = newItem;
                }else{
                    // New
                    pages.push(newItem);
                }
                const newPages = await editPages(pages, pageType);
                setPages(newPages);
                setLoading(false);
                close();
            }catch (e) {
                console.log(e);
                setLoading(false);
                if(e.info){
                    setMessage(e.info);
                }else{
                    setError(typeof e === 'string' ? e : e.message);
                }
            }
        },
        [pages, pageType]
    );
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullScreen={fullScreen}
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">Personen hinzufügen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <form>
                            {
                                message && (<div className={"panel"}>{message}</div>)
                            }
                            <Input
                                multiline
                                value={form.personIntro.title}
                                labelText={"Seitentitel"}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                handleChange={(e)=>{
                                    setForm(o => ({
                                        ...o,
                                        personIntro: {
                                            ...o.personIntro,
                                            title: e.target.value
                                        }
                                    }));
                                }}
                                error={error.pageTitle}
                            />
                            <Input
                                multiline
                                value={form.personIntro.text}
                                labelText={"Seitenbeschreibung"}
                                formControlProps={{
                                    fullWidth: true,
                                    style: {
                                        marginTop:  10
                                    }
                                }}
                                handleChange={(e)=>{
                                    setForm(o => ({
                                        ...o,
                                        personIntro: {
                                            ...o.personIntro,
                                            text: e.target.value
                                        }
                                    }));
                                }}
                                error={error.text}
                            />
                            <div style={{marginTop: 20}}>
                                Füge eine oder mehrere Personen hinzu
                            </div>
                            {
                                form.personIntro.items.map((item, index) => (
                                    <div key={index} className={"simpleBorderArea align-vertical"}>
                                        <div className={"person-image-wrapper"}>
                                            <div className={"person-image"} onClick={() => setEditImage({index: index, item: item})}>
                                                {
                                                    item.image && item.image.url ? (
                                                        <>
                                                            <img src={item.image.url} />
                                                            <div className={"image-overlay"}>
                                                                <ImageIcon fontSize={"large"} color={"primary"}/>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className={"image-overlay full-opacity"}>
                                                            <ImageIcon fontSize={"large"} color={"primary"}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <Input
                                            value={item.name}
                                            labelText={"Name der Person"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            handleChange={(e)=>{
                                                let array = form.personIntro.items;
                                                array[index] = {
                                                    ...array[index],
                                                    name: e.target.value
                                                };
                                                setForm(o => ({
                                                    ...o,
                                                    personIntro: {
                                                        ...o.personIntro,
                                                        items: array
                                                    }
                                                }));
                                            }}
                                            error={error.name && error.name[index]}
                                        />
                                        <Input
                                            value={item.tagLine}
                                            labelText={"Untertext, z.b. Trauzeug:in, Braut, Bräutigam, Vater der Braut, ..."}
                                            formControlProps={{
                                                fullWidth: true,
                                                style: {
                                                    marginTop:  10
                                                }
                                            }}
                                            handleChange={(e)=>{
                                                let array = form.personIntro.items;
                                                array[index] = {
                                                    ...array[index],
                                                    tagLine: e.target.value
                                                };
                                                setForm(o => ({
                                                    ...o,
                                                    personIntro: {
                                                        ...o.personIntro,
                                                        items: array
                                                    }
                                                }));
                                            }}
                                            error={error.tagLine && error.tagLine[index]}
                                        />
                                        <Input
                                            multiline
                                            value={item.text}
                                            labelText={"Freie Beschreibung"}
                                            formControlProps={{
                                                fullWidth: true,
                                                style: {
                                                    marginTop: 10
                                                }
                                            }}
                                            handleChange={(e)=>{
                                                let array = form.personIntro.items;
                                                array[index] = {
                                                    ...array[index],
                                                    text: e.target.value
                                                };
                                                setForm(o => ({
                                                    ...o,
                                                    personIntro: {
                                                        ...o.personIntro,
                                                        items: array
                                                    }
                                                }));
                                            }}
                                            error={error.text && error.text[index]}
                                        />
                                        <Input
                                            value={item.email}
                                            labelText={"Email"}
                                            formControlProps={{
                                                fullWidth: true,
                                                style: {
                                                    marginTop: 10
                                                }
                                            }}
                                            handleChange={(e)=>{
                                                let array = form.personIntro.items;
                                                array[index] = {
                                                    ...array[index],
                                                    email: e.target.value
                                                };
                                                setForm(o => ({
                                                    ...o,
                                                    personIntro: {
                                                        ...o.personIntro,
                                                        items: array
                                                    }
                                                }));
                                            }}
                                            error={error.email && error.email[index]}
                                        />
                                        <Input
                                            value={item.telephone}
                                            labelText={"Telefon"}
                                            formControlProps={{
                                                fullWidth: true,
                                                style: {
                                                    marginTop: 10
                                                }
                                            }}
                                            handleChange={(e)=>{
                                                let array = form.personIntro.items;
                                                array[index] = {
                                                    ...array[index],
                                                    telephone: e.target.value
                                                };
                                                setForm(o => ({
                                                    ...o,
                                                    personIntro: {
                                                        ...o.personIntro,
                                                        items: array
                                                    }
                                                }));
                                            }}
                                            error={error.telephone && error.telephone[index]}
                                        />
                                        <div style={{alignSelf: "flex-end", marginTop: 10}}>
                                            <Button
                                                aria-label="delete"
                                                onClick={
                                                    () => {
                                                        // Delete old Image if exists
                                                        try{
                                                            if(item.image && item.image.uri){
                                                                fire.storage().ref(item.image.uri).delete();
                                                            }
                                                        }catch (e) {
                                                            console.error(e);
                                                        }
                                                        // Edit Form
                                                        let array = form.personIntro.items;
                                                        array.splice(index, 1);
                                                        setForm(o => ({
                                                            ...o,
                                                            personIntro: {
                                                                ...o.personIntro,
                                                                items: array
                                                            }
                                                        }));
                                                    }
                                                }
                                                color="error"
                                            >
                                                Löschen
                                            </Button>
                                        </div>

                                    </div>
                                ))
                            }
                            <Button
                                onClick={() => setForm(o => (
                                    {
                                        ...o,
                                        personIntro: {
                                            ...o.personIntro,
                                            items: [...o.personIntro.items, emptyItem()]
                                        }
                                    }
                                ))}
                                color="secondary" variant={"outlined"} style={{marginTop: 20}}
                            >
                                Person hinzufügen
                            </Button>
                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="info">
                        Abbrechen
                    </Button>
                    <Button
                        onClick={async () => {
                            await save(form);
                            if(callback){
                                callback();
                            }
                        }}
                        color="primary"
                    >
                        {pages.findIndex(i => i.id === form.id) > -1 ? "Speichern" : "Hinzufügen"}
                    </Button>
                </DialogActions>
            </Dialog>
            <ImageCropperDialog
                setLoading={setLoading}
                open={!!editImage}
                close={() => {
                    setEditImage(null);
                }}
                callback={img => {
                    // Edit Form
                    let array = form.personIntro.items;
                    const index = editImage.index;
                    array[index].image = img;
                    setForm(o => ({
                        ...o,
                        personIntro: {
                            ...o.personIntro,
                            items: array
                        }
                    }));
                    setEditImage(null);
                }}
                fileName={editImage && editImage.item && editImage.item.id ? item.id + "/" + editImage.item.id : ""}
            />
        </>
    );
}
