import {useCallback, useEffect, useState} from "react";
import fire from "../modules/firebase";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

export const usePhotos = (setLoading, sorting = "uploaded", album = "all") => {
    const [photos, setPhotos] = useState([]);
    const [sortedPhotos, setSortedPhotos] = useState([]);
    // Only to trigger rerender (Array changes not detected by react)
    const [sortingKey, setSortingKey] = useState(sorting);
    const filterAlbum = i => album === "all" ? true : i.albums && i.albums[album];
    // Sorting
    useEffect(
        () => {
            switch (sorting) {
                case "uploaded":
                default:
                    setSortedPhotos(photos.filter(filterAlbum).sort((a, b) => (
                        // Check favorites
                        a.favorite === b.favorite ?
                            a[sorting] > b[sorting] ? -1 : a.fileModified > b.fileModified ? -1 : 1
                            : a.favorite ? -1 : 1
                    )));
                    setSortingKey(sorting);
                    break;
                case "fileModified":
                    setSortedPhotos(photos.filter(filterAlbum).sort((a, b) => (
                        // Check favorites
                        a.favorite === b.favorite ?
                            a[sorting] > b[sorting] ? 1 : -1
                            : a.favorite ? -1 : 1
                    )));
                    setSortingKey(sorting);
                    break;
            }

        },
        [photos, sorting, album]
    );
    // Fetching
    useEffect(
        () => {
            const photoRef = fire.database().ref("photos");
            photoRef.on('value', (snapshot) => {
                setLoading(true);
                const data = snapshot.val();
                let val = [];
                if (data){
                    Object.keys(data).forEach(
                        uid => {
                            Object.keys(data[uid]).forEach(
                                pid => {
                                    val.push({
                                        ...data[uid][pid],
                                        id: pid,
                                        uid: uid,
                                    });
                                }
                            );
                        }
                    );
                    setPhotos(val);
                }
                setLoading(false);
            });
        },
        []
    );
    // download all images callback
    const downloadPhoto = useCallback(
        (index, src) => {
            fetch(src, {
                method: "GET",
                headers: {
                }
            })
                .then(response => {
                    response.arrayBuffer().then(function(buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        const name = `Foto_${index+1}.${src.split("?")[0].split("#")[0].split('.').pop()}`;
                        link.setAttribute("download", name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        },
        []
    );
    // Download all photos from album
    const downloadAllPhotos = useCallback(
        async () => {
            // sort first
            setLoading(true);
            const downloadObjects = photos.filter(filterAlbum).sort((a, b) => a[sorting] > b[sorting] ? 1 : -1);
            const blobs = await Promise.all(downloadObjects.map(item => fetch(item.url).then(response => response.blob())));
            console.log(blobs);
            let zip = new JSZip();
            let folder = zip.folder('Fotos');
            blobs.forEach(
                (res, index) => {
                    let fileName = "Foto_" + index;
                    switch (res.type) {
                        case "image/png":
                            fileName = fileName + '.png';
                            break;
                        case "image/jpg":
                            fileName = fileName + '.jpg';
                            break;
                        case "image/jpeg":
                            fileName = fileName + '.jpeg';
                            break;
                        case "image/svg":
                            fileName = fileName + '.svg';
                            break;
                        case "image/gif":
                            fileName = fileName + '.gif';
                            break;
                        default:
                            fileName = fileName + '.jpg';
                    }
                    folder.file(fileName, res)
                }
            );
            const content = await folder.generateAsync({ type: "blob" });
            saveAs(content, "Fotos");
            setLoading(false);
        },
        [photos, setLoading, album]
    );
    return [sortedPhotos, downloadPhoto, downloadAllPhotos, sortingKey];
};
