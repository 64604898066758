import React, {useEffect, useState} from 'react';
import Button from '../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "./Transition";
import Input from "../Form/Input";
import Checkbox from "../Form/Checkbox";

export default function AddMessageGroupDialog(props) {
    const {open, submit, item, close, edit} = props;
    const [form, setForm] = useState({
        name: item && item.name ? item.name : "",
        onlyGuests: item && item.onlyGuests ? item.onlyGuests : false
    });
    useEffect(
        () => {
            setForm({
                name: item && item.name ? item.name : "",
                onlyGuests: item && item.onlyGuests ? item.onlyGuests : false
            });
        },
        [item, edit]
    );

    const [error, setError] = useState({});
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"xs"}
        >
            <DialogTitle id="alert-dialog-slide-title">{edit ? "Gruppe bearbeiten" : "Gruppe erstellen"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <form>
                        <Input
                            value={form.name}
                            labelText="Name"
                            id="name"
                            formControlProps={{
                                fullWidth: true,
                                style: {marginTop: 20}
                            }}
                            setForm={setForm}
                            type="text"
                            error={error.name}
                        />
                        <Checkbox
                            id={"onlyGuests"}
                            label={"Das Brautpaar von dieser Gruppe ausschließen"}
                            checked={form.onlyGuests}
                            setForm={setForm}
                            formControlProps={{
                                style: {
                                    marginTop: 20
                                }
                            }}
                        />
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="info">
                    Abbrechen
                </Button>
                <Button onClick={() => submit(form)} color="primary">
                    {edit ? "Bearbeiten" : "Hinzufügen"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
