import React from 'react';
import './progressBar.css';

export default props => {
    const {progress, ...p} = props;
    return (
        <div className={"progress-bar"} {...p}>
            <div className={"progress-indicator"} style={{width: progress + "%"}}>
            </div>
        </div>
    );
}
