import React, {useCallback, useState} from "react";
import Input from "../../components/Form/Input";
import {loginUser, registerForEvent, signupUser} from "../../actions/user";
import Button from "../../components/Form/Button";
import Select from "../Form/Select";
import Checkbox from "../Form/Checkbox";
import RadioButtons from "../Form/RadioButtons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {existsOrDefault} from "../../modules/functions";
import fire from "../../modules/firebase";

export default function SignupForm(props) {
    const {settings, style, setLoading, callback, isNew, defaultValues, isAdmin, uid} = props;
    const MAX_GUESTS = settings.signup.maxAddGuests;
    const [message, setMessage] = useState(props.message ? props.message : null);
    const [form, setForm] = useState(
        isNew ? {
            email: "",
            name: "",
            numberGuests: 0,
            attends: true,
            password: "",
            customText: "",
            foodOption: 0
        } : {
            uid: uid ? uid : fire.auth().currentUser.uid,
            ...defaultValues
        }
    );
    const [error, setError] = useState({});
    if(!isAdmin && (new Date()).getTime() > settings.signup.closingTime){
        // Registration closed
        return (<h3 style={{color: "var(--error)"}}>Weitere Zusagen zu unserer Feier sind leider nicht mehr möglich!</h3>)
    }
    return (
        <>
            {
                message && (<div className={"panel"}>{message}</div>)
            }
            <form className="panel" style={props.style}>
                {
                    isNew && (
                        <>
                            <Input
                                defaultValue={form.name}
                                labelText="Name"
                                id="name"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                setForm={setForm}
                                type="text"
                                error={error.name}
                            />
                            <Input
                                defaultValue={form.email}
                                labelText="Email"
                                id="email"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {marginTop: 20}
                                }}
                                setForm={setForm}
                                type="text"
                                error={error.email}
                            />
                            <Input
                                defaultValue={form.password}
                                labelText="Passwort"
                                id="password"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {marginTop: 20}
                                }}
                                setForm={setForm}
                                type="password"
                                error={error.password}
                            />
                            <span className={"info-text"}>
                                Bitte setze ein Passwort um dich im internen Bereich anmelden zu können um alle Informationen zur Feier zu erhalten.
                            </span>
                        </>
                    )
                }
                <Checkbox
                    id={"attends"}
                    label={"Ich möchte an der Feier teilnehmen"}
                    checked={form.attends}
                    setForm={setForm}
                    formControlProps={{
                        style: {
                            marginTop: 20
                        }
                    }}
                />
                {
                    (form.attends && settings.signup.foodPreferences) && (
                        <div>
                            <Accordion elevation={3}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={"accordion-value align-vertical-mobile"}>
                                        <strong>Speise Optionen</strong>
                                        <span className={"value-preview"}>{settings.signup.foodOptions[form.foodOption]}</span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RadioButtons
                                        value={form.foodOption}
                                        onChange={e => setForm(o => ({...o, foodOption: parseInt(e.target.value)}))}
                                        options={settings.signup.foodOptions.map((item, index) => ({value: index, label: item}))}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                }
                {
                    (form.attends && MAX_GUESTS > 0) && (
                        <>
                            <h3
                                style={{marginTop: 40}}
                            >
                                {settings.signup.titleAdditionalGuests}
                            </h3>
                            <Select
                                labelText={"Anzahl weiterer Gäste"}
                                id="numberGuests"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {marginTop: 20}
                                }}
                                value={form.numberGuests}
                                setForm={setForm}
                                error={error.numberGuests}
                                options={(Array.from(Array(MAX_GUESTS + 1).keys())).map((i, index) => ({value: index, label: index}))}
                            />
                            {
                                form.numberGuests > 0 && (
                                    <h3
                                        style={{marginTop: 40}}
                                    >
                                        Weitere Gäste
                                    </h3>
                                )
                            }

                            {
                                (Array.from(Array(form.numberGuests).keys())).map(
                                    (a, index) => (
                                        <div key={index}>
                                            <Input
                                                defaultValue={form[`guest${index+1}`]}
                                                labelText={[`Gast ${index+1}`]}
                                                id={`guest${index+1}`}
                                                formControlProps={{
                                                    fullWidth: true,
                                                    style: {marginTop: 10, marginBottom: 10}
                                                }}
                                                setForm={setForm}
                                                type="text"
                                                error={error[`guest${index+1}`]}
                                            />
                                            {
                                                settings.signup.foodPreferences && (
                                                    <div style={{marginBottom: 10}}>
                                                        <Accordion elevation={3}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <div className={"accordion-value align-vertical-mobile"}>
                                                                    <strong>Speise Optionen</strong>
                                                                    <span className={"value-preview"}>{settings.signup.foodOptions[existsOrDefault(form[`foodOption_guest${index+1}`], 0)]}</span>
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <RadioButtons
                                                                    value={existsOrDefault(form[`foodOption_guest${index+1}`], 0)}
                                                                    onChange={e => setForm(o => ({...o, [`foodOption_guest${index+1}`]: parseInt(e.target.value)}))}
                                                                    options={settings.signup.foodOptions.map((item, index) => ({value: index, label: item}))}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                )
                            }
                        </>
                    )
                }
                {
                    settings.signup.customTextfield && (
                        <div style={{marginTop: 20}}>
                            <h3>{settings.signup.customTextfieldText}</h3>
                            <Input
                                multiline
                                variant={"outlined"}
                                defaultValue={existsOrDefault(form.customText, "")}
                                labelText="Nachricht"
                                id="customText"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                setForm={setForm}
                                error={error.customText}
                            />
                        </div>
                    )
                }
                <span style={{marginTop: 20}}>
                    {settings.signup.infoText}
                </span>
                <Button
                    color={isNew ? "primary" : "secondary"}
                    type="button"
                    style={{marginTop: 20}}
                    variant={"contained"}
                    size={"large"}
                    onClick={
                        async () => {
                            setLoading(true);
                            setError({});
                            try{
                                const {updateObj} = isNew ? await signupUser(form) : await registerForEvent(form);
                                setLoading(false);
                                if(callback)
                                    callback(updateObj);
                            }catch (e) {
                                setLoading(false);
                                if(e.info){
                                    setMessage(e.info);
                                }else{
                                    setError(e);
                                }
                            }
                        }
                    }
                >
                    {
                        isNew ? "Anmelden" : "Speichern"
                    }
                </Button>
            </form>
        </>
    );
}
