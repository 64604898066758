import React, {useEffect, useState} from "react";
import Transition from "../Dialogs/Transition";
import Button from "../../components/Form/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import {createListArray} from "../../modules/functions";
import Checkbox from "../Form/Checkbox";

export default function MoveToAlbumDialog(props) {
    const {open, save, close, settings, items} = props;
    const [selected, setSelection] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const albums = settings.gallery && settings.gallery.albums ? createListArray(settings.gallery.albums) : [];
    useEffect(
        () => {
            if(!open){
                setSelection([]);
            }
        },
        [open]
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title">Zum Fotoalbum hinzufügen</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Wähle die Alben aus, zu denen die {items.length} Fotos hinzugefügt werden sollen.
                    <div className={"simpleBorderArea align-vertical"}>
                        {
                            albums.length < 1 ? (
                                <p>Bisher wurden keine Alben erstellt. Alle Fotos werden auf einer Seite dargestellt.</p>
                            ) : albums.map(
                                (item, index) => (
                                    <div key={index} className={"align-horizontal"} style={{cursor: "pointer"}}>
                                        <Checkbox
                                            id={item.id}
                                            label={item.name}
                                            checked={selected[item.id]}
                                            setForm={setSelection}
                                            formControlProps={{
                                                style: {
                                                    marginTop: 20
                                                }
                                            }}
                                        />

                                    </div>
                                )
                            )
                        }
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={close}
                    color="info"
                >
                    Abbrechen
                </Button>
                <Button
                    onClick={ () => {
                        save(selected);
                    }}
                    color="primary"
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>

    );
}
