import React from "react";
import LocationItem from "./Item";

export default function LocationsPage(props){
    const {item} = props;
    return (
        <>
            <div style={{backgroundColor: "var(--backgroundSecondaryLight)"}}>
                <div className={"center-text content-box"} style={{paddingBottom: 40}}>
                    <h1>{item.locations.title}</h1>
                    <span>{item.locations.text}</span>
                </div>
            </div>
            <div className={"big-content-box"}>
                {
                    item.locations.items.map(
                        (item, index) => (
                            <LocationItem key={index} item={item} />
                        )
                    )
                }
            </div>
        </>
    );
}
