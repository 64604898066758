import React, {useCallback, useState} from "react";
import Pages from "../../components/Layout/Pages";
import {useHistory} from "react-router-dom";
import PagePreview from "../../components/ContentPages/PagePreview";
import {createID, createListArray} from "../../modules/functions";
import AddImageDialog from "../../components/Pages/Image/Dialog";
import SignupPreferences from "../../components/Dialogs/SignupPreferences";
import Logout from "../../components/Signup/Logout";
import LocationsDialog from "../../components/Pages/Locations/Dialog";
import CountdownDialog from "../../components/Pages/Countdown/Dialog";
import TimelineDialog from "../../components/Pages/Timeline/Dialog";
import RoomsDialog from "../../components/Pages/Rooms/Dialog";
import PersonIntroDialog from "../../components/Pages/PersonIntro/Dialog";
import GallerySettings from "../../components/Photos/Settings";

export default function AdminSettings(props){
    const {publicPages, internalPages, setPublicPages, setInternalPages, setLoading, settings, setSettings} = props;
    const history = useHistory();
    const publicPagesArray = createListArray(publicPages);
    const internalPagesArray = createListArray(internalPages);
    const [addImage, setAddImage] = useState(false);
    const [addSignup, setAddSignup] = useState(false);
    const [addLocations, setAddLocations] = useState(false);
    const [addCountdown, setAddCountdown] = useState(false);
    const [addTimeline, setAddTimeline] = useState(false);
    const [addRooms, setAddRooms] = useState(false);
    const [addPersonIntro, setAddPersonIntro] = useState(false);
    const createNew = useCallback(
        (key, type) => {
            switch (key) {
                case "html":
                    history.push("/admin/edit-page", {
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "image":
                    setAddImage({
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "signup":
                    setAddSignup({
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "locations":
                    setAddLocations({
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "rooms":
                    setAddRooms({
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "personIntro":
                    setAddPersonIntro({
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "countdown":
                    setAddCountdown({
                        id: createID(),
                        pageType: type
                    });
                    break;
                case "timeline":
                    setAddTimeline({
                        id: createID(),
                        pageType: type
                    });
                    break;
            }
        },
        []
    );
    return(
        <>
            {
                // Dialogs
            }
            <AddImageDialog
                open={!!addImage}
                close={()=>setAddImage(false)}
                item={addImage}
                pages={addImage && addImage.pageType === "internal" ? internalPages : publicPages}
                setPages={addImage && addImage.pageType === "internal" ? setInternalPages : setPublicPages}
            />
            <SignupPreferences
                open={!!addSignup}
                close={()=>setAddSignup(false)}
                item={addSignup}
                pages={publicPages}
                setPages={setPublicPages}
                signupSettings={settings.signup}
                setSignupSettings={obj => setSettings(o => ({...o, signup: obj}))}
            />
            <LocationsDialog
                open={!!addLocations}
                close={() => setAddLocations(false)}
                pages={addLocations && addLocations.pageType === "internal" ? internalPages : publicPages}
                setPages={addLocations && addLocations.pageType === "internal" ? setInternalPages : setPublicPages}
                item={addLocations}
                setLoading={setLoading}
            />
            <RoomsDialog
                open={!!addRooms}
                close={() => setAddRooms(false)}
                pages={addRooms && addRooms.pageType === "internal" ? internalPages : publicPages}
                setPages={addRooms && addRooms.pageType === "internal" ? setInternalPages : setPublicPages}
                item={addRooms}
                setLoading={setLoading}
            />
            <PersonIntroDialog
                open={!!addPersonIntro}
                close={() => setAddPersonIntro(false)}
                pages={addPersonIntro && addPersonIntro.pageType === "internal" ? internalPages : publicPages}
                setPages={addPersonIntro && addPersonIntro.pageType === "internal" ? setInternalPages : setPublicPages}
                item={addPersonIntro}
                setLoading={setLoading}
            />
            <CountdownDialog
                open={!!addCountdown}
                close={() => setAddCountdown(false)}
                pages={addCountdown && addCountdown.pageType === "internal" ? internalPages : publicPages}
                setPages={addCountdown && addCountdown.pageType === "internal" ? setInternalPages : setPublicPages}
                item={addCountdown}
                setLoading={setLoading}
            />
            <TimelineDialog
                open={!!addTimeline}
                close={() => setAddTimeline(false)}
                pages={addTimeline && addTimeline.pageType === "internal" ? internalPages : publicPages}
                setPages={addTimeline && addTimeline.pageType === "internal" ? setInternalPages : setPublicPages}
                item={addTimeline}
                setLoading={setLoading}
            />
            {
                // Rendered Items
            }
            <Pages
                list={[

                    (
                        <>
                            <div style={{backgroundColor: "var(--backgroundSecondaryLight)"}}>
                                <div className={"center-text content-box"}>
                                    <h2>Inhalte deiner Seiten</h2>
                                    <p>
                                        Editiere die Informationen die du deinen unregistrierten Besuchern und deinen registrierten Gästen mitteilen willst.
                                    </p>
                                </div>
                            </div>
                            <div className={"center-text content-box"}>
                                <div className={"content-page-preview"}>
                                    <PagePreview
                                        label={"Besucher Infoseite"}
                                        items={publicPagesArray}
                                        createNew={(key) => createNew(key, "public")}
                                        pageType={"public"}
                                        setLoading={setLoading}
                                        setItems={setPublicPages}
                                        editImage={item => setAddImage({...item, pageType: "public"})}
                                        editSignup={id => setAddSignup({id: id, pageType: "public"})}
                                        editLocations={item => setAddLocations({...item, pageType: "public"})}
                                        editCountdown={item => setAddCountdown({...item, pageType: "public"})}
                                        editTimeline={item => setAddTimeline({...item, pageType: "public"})}
                                        editRooms={item => setAddRooms({...item, pageType: "public"})}
                                        editPersonIntro={item => setAddPersonIntro({...item, pageType: "public"})}
                                    />
                                    <PagePreview
                                        label={"Gäste Infoseite"}
                                        items={internalPagesArray}
                                        createNew={(key) => createNew(key, "internal")}
                                        pageType={"internal"}
                                        setLoading={setLoading}
                                        setItems={setInternalPages}
                                        editImage={item => setAddImage({...item, pageType: "internal"})}
                                        editSignup={id => setAddSignup({id: id, pageType: "internal"})}
                                        editLocations={item => setAddLocations({...item, pageType: "internal"})}
                                        editCountdown={item => setAddCountdown({...item, pageType: "internal"})}
                                        editTimeline={item => setAddTimeline({...item, pageType: "internal"})}
                                        editRooms={item => setAddRooms({...item, pageType: "internal"})}
                                        editPersonIntro={item => setAddPersonIntro({...item, pageType: "internal"})}
                                    />
                                </div>
                            </div>
                        </>
                    ),
                    <GallerySettings settings={settings} setSettings={setSettings} setLoading={setLoading} />,
                    (
                        <Logout />
                    )
                ]}
            />
        </>
    )

}
