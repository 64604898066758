import {useCallback, useEffect, useState} from "react";

export const useHighlightBackground = () => {
    useEffect(
        () => {
            document.body.style.backgroundColor = "var(--primary)";
            document.body.style.background = "linear-gradient(45deg, var(--primary), var(--secondary))";
            document.body.style.minHeight = "100vh";
            return () => {
                document.body.style.backgroundColor = "var(--background)";
                document.body.style.background = "var(--background)";
                document.body.style.minHeight = "unset";
            }
        },
        []
    );
};

export const useMobileListener = () => {
    const [isMobile, setMobile] = useState(true);
    const setMobileActive = useCallback(
        (e) => {
            // Set redux
            setMobile(e.matches);
        },
        []
    );
    const mobileQuery = window.matchMedia("(max-width: 768px)");
    useLayoutQuery(mobileQuery, setMobileActive);
    return isMobile;
};

export const useLayoutQuery = (query, callback, noInitialTrigger = false) => {
    useEffect(
        () => {
            let event = false;
            // Initial Value
            if(!noInitialTrigger)
                callback(query);
            // Listeners
            try {
                // Chrome & Firefox
                event = true;
                query.addEventListener('change', callback);
            } catch (e1) {
                try {
                    // Safari
                    query.addListener(callback);
                } catch (e2) {
                    console.error(e2);
                }
            }
            return () => event ? query.removeEventListener('change', callback) : query.removeListener(callback)
        },
        []
    );
};
