import React, {useState} from "react";
import Button from "../../components/Form/Button";
import Pages from "../../components/Layout/Pages";
import {useHistory} from "react-router-dom";
import {logoutUser, removeGuest} from "../../actions/user";
import {createGuestListArray, createListArray} from "../../modules/functions";
import GuestListItem from "../../components/Guests/ListItem";
import Input from "../../components/Form/Input";
import DeleteDialog from "../../components/Dialogs/Delete";
import Logout from "../../components/Signup/Logout";
import FoodOptionsIndicator from "../../components/Guests/FoodOptionsIndicator";
import EditSignupFormDialog from "../../components/Dialogs/EditSignupForm";
import GuestlistCSVLink from "../../components/Export/GuestlistCSVLink";

export default function Guests(props){
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [deleteGuest, setDeleteGuest] = useState(false);
    const [editGuest, setEditGuest] = useState(false);
    const {guestList, guestListError, setLoading, setGuests, settings} = props;
    const {array: guestListArray, countGuests, countRegistrations} = createGuestListArray(guestList);
    const filteredGuestList = guestListArray.filter(item => item.name.includes(search));
    return(
        <>
            <DeleteDialog
                open={!!deleteGuest}
                fail={() => setDeleteGuest(false)}
                success={async () => {
                    setLoading(true);
                    try{
                        await removeGuest(deleteGuest);
                        const {[deleteGuest.id]:_, ...r} = guestList;
                        setGuests(r);
                        setLoading(false);
                        setDeleteGuest(false);
                    }catch (e) {
                        console.error(e);
                        setLoading(false);
                    }
                }}
                item={deleteGuest && (
                    <div>
                        <span className={"guest-name"}>
                            {deleteGuest.name}
                        </span>
                        {
                            (deleteGuest.attends && deleteGuest.numberGuests > 0) && (
                                ` +${deleteGuest.numberGuests} weitere:`
                            )
                        }
                        {
                            createListArray(deleteGuest.guests).map(
                                (item, index, array) => (<span key={index}>{item.name + (index < array.length - 1 ? ", " : "")}</span>)
                            )
                        }
                    </div>
                )}
            />
            <EditSignupFormDialog
                isAdmin
                open={!!editGuest}
                fail={() => setEditGuest(false)}
                guestData={editGuest}
                settings={settings}
                setLoading={setLoading}
                callback={(updateObj) => {
                    const {...array} = guestList;
                    const {id, ...r} = updateObj;
                    r.guestUpdateTime = (new Date()).getTime();
                    array[editGuest.id] = {
                        ...editGuest,
                        ...r
                    };
                    setGuests(array);
                    setEditGuest(false);
                }}
            />
            <Pages
                list={[
                    (
                        <div className={"center-text content-box"}>
                            <h2>Eure Gästeliste</h2>
                            <div className={"space-horizontal"}>
                                <div
                                    className={"panel primary bordered"}
                                    style={{flex: 1, textAlign: "center", paddingTop: 10, paddingBottom: 10, marginRight: 20}}
                                >
                                    <span className={"indicator-number"}>
                                        {countGuests}
                                    </span>
                                    <span className={"indicator-label"}>
                                        Gäste
                                    </span>
                                </div>
                                <div
                                    className={"panel primary bordered"}
                                    style={{flex: 1, textAlign: "center", paddingTop: 10, paddingBottom: 10}}
                                >
                                    <span className={"indicator-number"}>
                                        {countRegistrations}
                                    </span>
                                    <span className={"indicator-label"}>
                                        Anmeldungen
                                    </span>
                                </div>
                            </div>
                            {
                                settings.signup.foodPreferences && (
                                    <FoodOptionsIndicator
                                        settings={settings}
                                        guestList={guestList}
                                    />
                                )
                            }
                            <div>
                                <Input
                                    defaultValue={search}
                                    labelText="Suche"
                                    id="search"
                                    variant={"outlined"}
                                    fullWidth
                                    style={{
                                        marginTop: 20
                                    }}
                                    className={"bigBorder roundInput"}
                                    handleChange={(e)=>setSearch(e.target.value)}
                                    type="text"
                                />
                            </div>
                            <ul className={"simpleList guestList bigBorder"}>
                                {
                                    guestListArray.length === 0
                                        ? (
                                            <h3 style={{color: "var(--error)"}}>Es haben sich noch keine Gäste angemeldet</h3>
                                        )
                                        : filteredGuestList.length === 0 ? (
                                            <h3 style={{color: "var(--error)"}}>Keine Gäste zur Suchanfrage gefunden</h3>
                                        )
                                        : filteredGuestList.map(
                                            (item, index) => (
                                                <GuestListItem
                                                    key={item.id} item={item} index={index}
                                                    deleteItem={() => setDeleteGuest(item)}
                                                    settings={settings}
                                                    editItem={() => setEditGuest(item)}
                                                />
                                            )
                                        )
                                }
                            </ul>
                            {
                                guestListArray.length > 0 && (
                                    <Button
                                        standardWidth
                                        variant={"outlined"}
                                        round
                                        color={"secondary"}
                                        onClick={() => window.open(`mailto:${guestListArray.map(item => item.email).join(",").slice(0, -1)}`)}
                                    >
                                        Allen Gästen schreiben
                                    </Button>
                                )
                            }
                            <GuestlistCSVLink guestListArray={guestListArray} settings={settings} style={{marginTop: 20}} />
                        </div>
                    ),
                    (
                        <Logout />
                    )
                ]}
            />
        </>
    )

}
