import fire, {firebase} from "../modules/firebase";

export const changeSignupSettings = (form) => new Promise(async (resolve, reject) => {
    try{
        await fire.firestore().collection("admin").doc("settings").update({
            signup: form
        });
        resolve(form);
    }catch (e) {
        reject(e);
    }
});

export const changeGallerySettings = (form) => new Promise(async (resolve, reject) => {
    try{
        await fire.firestore().collection("admin").doc("settings").update({
            gallery: form
        });
        resolve(form);
    }catch (e) {
        reject(e);
    }
});
