import React, {useCallback} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "./select.css";

export default function CustomSelect(props){
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        selectProps,
        value,
        error,
        white,
        inputRootCustomClasses,
        success,
        handleChange,
        disabled,
        setForm,
        options
    } = props;
    const direct_handleChange = useCallback(e => {
        if(setForm){
            setForm(f => ({
                ...f,
                [id]: e.target.value
            }));
        }else{
            console.error("Need to pass setForm");
        }
    }, [id, setForm]);
    return (
        <FormControl {...formControlProps}>
            <InputLabel
                htmlFor={id} id={`${id}-label`}
                error={!!error}
                {...labelProps}
            >
                {labelText}
            </InputLabel>
            <Select
                labelId={`${id}-label`}
                id={id}
                value={value}
                onChange={handleChange ? handleChange : direct_handleChange}
                error={!!error}
                disabled={disabled}
                {...selectProps}
            >
                {
                    options.map(
                        (item) => (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    )
                }
            </Select>
            {
                error && <span className={"error-text"}>{error}</span>
            }
        </FormControl>
    );
}
