import {useCallback, useEffect, useRef, useState} from "react";
import fire,{firebase} from "../modules/firebase";

export const useMessages = (setLoading, user) => {
    const [messages, setMessages] = useState({});
    useEffect(
        () => {
            const ref = fire.database().ref("messages");
            setLoading(true);
            const subscribe = snapshot => {
                const data = snapshot.val();
                if(data){
                    if(user.isGuest){
                        // Füge alle Nachrichten von allen Gruppen hinzu
                        setMessages(data);
                    }else{
                        // Nur öffentliche Gruppen
                        const d = {};
                        Object.keys(data).filter(
                            key => !data[key].onlyGuests
                        ).forEach(key => {
                            d[key] = data[key];
                        });
                        setMessages(d);
                    }
                }
                setLoading(false);
            };
            ref.on("value", subscribe);
            return () => ref.off();
        },
        [user]
    );
    // addMessage
    const addMessage = useCallback(
        (group, message) => new Promise(async (resolve, reject) => {
            const ref = fire.database().ref(`messages/${group}/messages`);
            setLoading(true);
            try{

                await ref.push({
                    text: message,
                    user: user,
                    time: firebase.database.ServerValue.TIMESTAMP
                });
                setLoading(false);
                resolve();
            }catch (e) {
                setLoading(false);
                reject(e);
            }
        }),
        [user]
    );
    // editMessage
    const editMessage = useCallback(
        (id, group, message) => new Promise(async (resolve, reject) => {
            const ref = fire.database().ref("messages");
            setLoading(true);
            try{
                await ref.update({
                    [`${group}/messages/${id}/text`]: message,
                    [`${group}/messages/${id}/edited`]: firebase.database.ServerValue.TIMESTAMP,
                });
                setLoading(false);
                resolve();
            }catch (e) {
                setLoading(false);
                reject(e);
            }
        }),
        []
    );
    // deleteMessage
    const deleteMessage = useCallback(
        async (id, group) => new Promise(async (resolve, reject) => {
            const ref = fire.database().ref(`messages/${group}/messages/${id}`);
            setLoading(true);
            try{
                await ref.remove();
                setLoading(false);
                resolve();
            }catch (e) {
                setLoading(false);
                reject(e);
            }
        }),
        []
    );
    // addGroup
    const addGroup = useCallback(
        (settings) => new Promise(async (resolve, reject) => {
            const ref = fire.database().ref("messages");
            if(!settings.name || settings.name.length < 5){
                reject({name: "Der Name muss mindestens 5 Zeichen lang sein"});
            }else{
                setLoading(true);
                try{
                    await ref.push({
                        messages: {},
                        user: user,
                        ...settings
                    });
                    setLoading(false);
                    resolve();
                }catch (e) {
                    setLoading(false);
                    reject(e);
                }
            }
        }),
        [user]
    );
    // editGroup
    const editGroup = useCallback(
        (id, settings) => new Promise(async (resolve, reject) => {
            const ref = fire.database().ref("messages");
            if(!settings.name || settings.name.length < 5){
                reject({name: "Der Name muss mindestens 5 Zeichen lang sein"});
            }else{
                setLoading(true);
                try{
                    const update = {};
                    Object.keys(settings).forEach(
                        key => {
                            update[`${id}/${key}`] = settings[key];
                        }
                    );
                    await ref.update(update);
                    setLoading(false);
                    resolve();
                }catch (e) {
                    setLoading(false);
                    reject(e);
                }
            }
        }),
        []
    );
    // deleteGroup
    const deleteGroup = useCallback(
        async (id) => new Promise(async (resolve, reject) => {
            const ref = fire.database().ref(`messages/${id}`);
            setLoading(true);
            try{
                await ref.remove();
                setLoading(false);
                resolve();
            }catch (e) {
                setLoading(false);
                reject(e);
            }
        }),
        []
    );
    return [messages, addMessage, editMessage, deleteMessage, addGroup, editGroup, deleteGroup];
};
