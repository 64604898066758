import React, {useCallback, useState} from "react";
import Input from "../../components/Form/Input";
import {sendNewPassword} from "../../actions/user";
import Button from "../../components/Form/Button";

export default function ForgotPasswordForm(props) {
    const [message, setMessage] = useState(props.message ? props.message : null);
    const [form, setForm] = useState({
        email: ""
    });
    const [error, setError] = useState({});
    return (
        <>
            {
                message && (<div className={"panel"}>{message}</div>)
            }
            <form className="panel">
                <Input
                    defaultValue={form.email}
                    labelText="Email"
                    id="email"
                    formControlProps={{
                        fullWidth: true
                    }}
                    setForm={setForm}
                    type="text"
                    error={error.email}
                />
                <Button
                    color={"primary"}
                    type="button"
                    style={{marginTop: 40}}
                    variant={"contained"}
                    size={"large"}
                    onClick={
                        async () => {
                            setError({});
                            try{
                                await sendNewPassword(form);
                            }catch (e) {
                                if(e.email || e.password){
                                    setError(e);
                                }else{
                                    setMessage(e.info);
                                }
                            }
                        }
                    }
                >
                    Sende neues Passwort
                </Button>
            </form>
        </>
    );
}
