import React, {useEffect} from 'react';
import './App.css';
import {
    Switch,
    Route,
    Link
} from "react-router-dom";
import LandingPage from "./views/home";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import { createGlobalStyle } from 'styled-components';
import {colors} from "./constants";
import LoadingScreen from "./components/Loading/Screen";
import {useAuthObserver, useGuestData, useHostData} from "./hooks/auth";
import {useLoading} from "./hooks/loading";
import Home from "./views/internal/home";
import Login from "./views/login";
import RequiredAuth from "./container/RequiredAuth";
import InternalLayout from "./components/Layout/Internal";
import Profile from "./views/internal/profile";
import Messages from "./components/Messages";
import RequiredAdmin from "./container/RequiredAdmin";
import Guests from "./views/admin/guests";
import AdminSettings from "./views/admin/settings";
import {usePages} from "./hooks/pages";
import EditPage from "./views/admin/edit-page";
import {useSettings} from "./hooks/settings";
import Photos from "./views/internal/photos";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.primary,
            contrastText: colors.primaryText,
        },
        secondary: {
            light: colors.secondaryLight,
            contrastText: colors.secondaryText,
            main: colors.secondary
        },
        error: {
            main: colors.error
        },
        success: {
            main: colors.success
        }
    }
});

const GlobalStyles = createGlobalStyle`
  html {
    --primary: ${colors.primary};
    --secondary: ${colors.secondary};
    --secondaryLight: ${colors.secondaryLight};
    --secondaryText: ${colors.secondaryText};
    --primaryText: ${colors.primaryText};
    --text: ${colors.text};
    --background: ${colors.background};
    --backgroundPrimaryLight: ${colors.backgroundPrimaryLight};
    --backgroundSecondaryLight: ${colors.backgroundSecondaryLight};
    --error: ${colors.error};
    --success: ${colors.success};
    --contrast-text: ${colors.contrastText};
  }
`;

function App() {
    const [loadingUser, user, error, setUser] = useAuthObserver();
    const [loading, setLoading] = useLoading();
    const [guestList, setGuests, guestListError] = useHostData(user, loadingUser,{loading, setLoading});
    const guestData = useGuestData(user);
    const [publicPages, internalPages, setPublicPages, setInternalPages] = usePages(user, setLoading);
    const [settings, setSettings] = useSettings();
    return (
        <>
            <GlobalStyles />
            <ThemeProvider theme={theme}>
                <div className="App">
                    <Switch>
                        <Route path="/internal/home">
                            <RequiredAuth
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <Home user={user} loading={loading} setLoading={setLoading} pages={internalPages}/>
                                </InternalLayout>
                            </RequiredAuth>
                        </Route>
                        <Route path="/internal/profile">
                            <RequiredAuth
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <Profile user={user} setLoading={setLoading} guestData={guestData} settings={settings}/>
                                </InternalLayout>
                            </RequiredAuth>
                        </Route>
                        <Route path="/internal/photos">
                            <RequiredAuth
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <Photos user={user} settings={settings} setLoading={setLoading}/>
                                </InternalLayout>
                            </RequiredAuth>
                        </Route>
                        <Route path="/internal/messages">
                            <RequiredAuth
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <Messages user={user} setLoading={setLoading}/>
                                </InternalLayout>
                            </RequiredAuth>
                        </Route>
                        <Route path="/admin/messages">
                            <RequiredAdmin
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <Messages user={user} setLoading={setLoading}/>
                                </InternalLayout>
                            </RequiredAdmin>
                        </Route>
                        <Route path="/admin/guests">
                            <RequiredAdmin
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <Guests
                                        user={user}
                                        setLoading={setLoading} guestList={guestList} error={guestListError}
                                        setGuests={setGuests}
                                        settings={settings}
                                    />
                                </InternalLayout>
                            </RequiredAdmin>
                        </Route>
                        <Route path="/admin/settings">
                            <RequiredAdmin
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <AdminSettings
                                        user={user} setLoading={setLoading} guestList={guestList} error={guestListError}
                                        publicPages={publicPages}
                                        setPublicPages={setPublicPages}
                                        internalPages={internalPages}
                                        setInternalPages={setInternalPages}
                                        settings={settings}
                                        setSettings={setSettings}
                                    />
                                </InternalLayout>
                            </RequiredAdmin>
                        </Route>
                        <Route path="/admin/edit-page">
                            <RequiredAdmin
                                user={user} setLoading={setLoading} loading={loadingUser}
                                settings={settings}
                            >
                                <InternalLayout user={user} settings={settings}>
                                    <EditPage
                                        user={user} setLoading={setLoading}
                                        publicPages={publicPages}
                                        setPublicPages={setPublicPages}
                                        internalPages={internalPages}
                                        setInternalPages={setInternalPages}
                                    />
                                </InternalLayout>
                            </RequiredAdmin>
                        </Route>
                        <Route path="/login">
                            <Login setLoading={setLoading} settings={settings}/>
                        </Route>
                        <Route path="/">
                            <LandingPage setLoading={setLoading} pages={publicPages} settings={settings}/>
                        </Route>
                    </Switch>
                </div>
            </ThemeProvider>
            <LoadingScreen loadingUser={loadingUser} loadingProcess={loading}/>
        </>
    );
}

export default App;
