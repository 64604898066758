import React, {useEffect} from 'react';
import Pages from "../components/Layout/Pages";
import Button from "../components/Form/Button";
import {useHistory} from "react-router-dom";
import {createPagesArray} from "../modules/pages";

export default function LandingPage(props){
    const history = useHistory();
    const {pages} = props;
    const pagesArray = createPagesArray(pages, {setLoading: props.setLoading}, props.settings);
    return (
        <>
            <div className={"top-nav"}>
                <Button
                    color={"primary"} variant={"contained"}
                    round
                    onClick={() => history.push("/login")}
                >
                    Login
                </Button>
            </div>
            <Pages
                list={pagesArray}
            />
        </>
    );
}
