import React from "react";
import {useUser} from "../hooks/auth";
import Login from "../views/login";

export default function RequiredAuth({user, children, setLoading, loading, settings}){
    if(loading || !user || !user.uid){
        return(<Login settings={settings} message={"Du musst dich für dieses Event erst anmelden oder dich einloggen."} setLoading={setLoading}/>);
    }else{
        return children;
    }

}
