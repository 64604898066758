import fire, {firebase} from "../modules/firebase";
import {existsOrDefault} from "../modules/functions";

export const loginUser = form => new Promise((resolve, reject) => {
    fire.auth().signInWithEmailAndPassword(form.email.toLowerCase(), form.password).then(
        () => {
            resolve();
        },
        (errorObject) => {
            // Custom Error Handling (based on firebase Error-Codes)
            switch (errorObject.code){
                case "auth/invalid-email":
                case "auth/user-not-found":
                    reject({email: "Falsche Email"});
                    break;
                case "auth/wrong-password":
                    reject({password: "Falsches Passwort"});
                    break;
                default:
                    reject({info: "Something went wrong: "+errorObject.message});
            }
        }
    ).catch(
        e => reject(e)
    );
});

export const signupUser = form => new Promise((resolve, reject) => {
    let errors = {};
    if(form.name.length < 4){
        errors.name = "Der Name muss mindestens 4 Zeichen lang sein.";
    }
    if(form.password.length < 6){
        errors.password = "Das Passwort muss mindestens 6 Zeichen lang sein.";
    }
    if(form.email.length < 4 || !form.email.indexOf("@") || !form.email.indexOf(".")){
        errors.email = "Die Email Adresse scheint nicht korrekt zu sein.";
    }
    if(form.attends){
        (Array.from(Array(form.numberGuests).keys())).forEach(
            (a, index) => {
                if(!form[`guest${index+1}`] || form[`guest${index+1}`] === "" || form[`guest${index+1}`].length < 3){
                    errors[`guest${index+1}`] = "Der Name muss mindestens 3 Zeichen lang sein.";
                }
            }
        );
    }
    if(Object.keys(errors).length!==0){
        console.log(errors);
        reject(errors);
    }else{
        fire.auth().createUserWithEmailAndPassword(form.email.toLowerCase(), form.password).then(
            result => {
                const {uid} = result.user;
                registerForEvent(
                    {
                        ...form,
                        isNew: true,
                        uid: uid,
                    }
                ).then(
                    secResult => {
                        const obj = {
                            uid: uid,
                            ...secResult.updateObj
                        };
                        resolve(obj)
                    }
                ).catch(e => reject({info: "Etwas ist schief gelaufen: " + (e.message ? e.message : e.info)}))
            }
        ).catch(
            e => {
                if(!e.code){
                    reject(e);
                }
                switch (e.code){
                    case "auth/invalid-email":
                        reject({email: "Die Email Adresse scheint nicht korrekt zu sein."});
                        break;
                    case "auth/email-already-in-use":
                        reject({email: "Die Email Adresse hat sich bereits angemeldet."});
                        break;
                    case "auth/weak-password":
                        reject({password: "Das Passwort ist zu schwach."});
                        break;
                    default:
                        reject({info: "Etwas ist schief gelaufen: " + e.message});
                }
            }
        );
    }
});

export const registerForEvent = form => new Promise(async (resolve, reject) => {
    let errors = {};
    if(form.attends){
        (Array.from(Array(form.numberGuests).keys())).forEach(
            (a, index) => {
                if(!form[`guest${index+1}`] || form[`guest${index+1}`] === "" || form[`guest${index+1}`].length < 3){
                    errors[`guest${index+1}`] = "Der Name muss mindestens 3 Zeichen lang sein.";
                }
            }
        );
    }
    if(!form.uid){
        errors.message = "Kein User zu der Registrierung assoziiert.";
    }
    if(Object.keys(errors).length!==0){
        reject(errors);
    }else{
        try{
            const guests = {};
            let doubles = 0;
            if(form.attends){
                (Array.from(Array(form.numberGuests).keys())).forEach(
                    (a, index) => {
                        if(form[`guest${index+1}`] === form.name){
                            // Same Name --> dont count twice
                            doubles++;
                        }else{
                            guests[index - doubles] = {
                                name: form[`guest${index+1}`],
                                foodOption: existsOrDefault(form[`foodOption_guest${index+1}`], 0)
                            };
                        }
                    }
                );
            }
            const updateObj = {
                isGuest: true,
                attends: form.attends,
                numberGuests: form.numberGuests - doubles,
                guests: guests,
                guestUpdateTime: firebase.firestore.FieldValue.serverTimestamp()
            };
            if(form.name){
                updateObj.name = form.name;
            }
            if(form.email){
                updateObj.email = form.email.toLowerCase();
            }
            if(form.foodOption || form.foodOption === 0){
                updateObj.foodOption = parseInt(form.foodOption);
            }
            if(form.customText){
                updateObj.customText = form.customText;
            }
            let response = {};
            if(form.isNew){
                response = await fire.firestore().collection("guests").doc(form.uid).set(
                    updateObj
                );
            }else{
                response = await fire.firestore().collection("guests").doc(form.uid).update(
                    updateObj
                );
            }
            resolve({response, updateObj});
        }catch (e) {
            reject(e.message);
        }
    }
});

export const removeGuest = form => new Promise(async (resolve, reject) => {
    let errors = {};
    if(Object.keys(errors).length!==0){
        reject(errors);
    }else{
        try{
            await fire.firestore().collection("guests").doc(form.id).delete();
            // Not deleted yet
            resolve();
        }catch (e) {
            reject(e.message);
        }
    }
});

export const sendNewPassword = form => new Promise((resolve, reject) => {
    let errors = {};
    if(form.email.length < 4 && !form.email.indexOf("@") && !form.email.indexOf(".")){
        errors.email = "Die Email Adresse scheint nicht korrekt zu sein.";
    }
    if(Object.keys(errors).length!==0){
        reject(errors);
    }else{
        fire.auth().sendPasswordResetEmail(form.email.toLowerCase()).then(
            result => {
                resolve(result);
            }
        ).catch(
            e => {
                if(e.code === "auth/invalid-email" || e.code === "auth/user-not-found"){
                    reject({email: "Keine Anmeldung unter dieser Email gefunden."});
                }else{
                    reject({email:e.message});
                }
            }
        );
    }
});

export const logoutUser = () => new Promise((resolve, reject) => {
    fire.auth().signOut().then(function() {
        resolve();
    }).catch(function(error) {
        reject(error);
    });
});
