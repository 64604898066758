import React from "react";
import classNames from "classnames";
import {useHistory, useLocation} from "react-router-dom";

export default function NavItem(props) {
    const {
        children,
        path
    } = props;
    const location = useLocation();
    const history = useHistory();
    const active = path === location.pathname;
    const classes = classNames({
        navItem: true,
        active
    });
    return (
        <span
            className={classes}
            onClick={() => {
                if(!active){
                    history.push(path)
                }
            }}
        >
            {children}
        </span>
    );
}
