import {useEffect, useState} from "react";
import fire from "../modules/firebase";

export const usePages = (user, setLoading) => {
    const [publicPages, setPublicPages] = useState([]);
    const [internalPages, setInternalPages] = useState([]);
    useEffect(
        () => {
            setLoading(true);
            let count = 1;
            if(user && user.uid){
                count++;
                fire.firestore().collection("pages").doc("internal").get().then(
                    doc => {
                        const data = doc.data();
                        setInternalPages(data.content);
                        count--;
                        if(count === 0){
                            // Callback
                            setLoading(false);
                        }
                    }
                ).catch(
                    e => console.error(e.message)
                );
            }
            fire.firestore().collection("pages").doc("public").get().then(
                doc => {
                    const data = doc.data();
                    setPublicPages(data.content);
                    count--;
                    if(count === 0){
                        // Callback
                        setLoading(false);
                    }
                }
            ).catch(
                e => {
                    console.error(e.message);
                    setLoading(false);
                }
            );
        },
       [user]
    );
    return [publicPages, internalPages, setPublicPages, setInternalPages];
};
