import React, {useEffect, useRef, useState} from "react";
import {createMessagesArray} from "../../modules/functions";
import Post from "./Post";
import DeleteDialog from "../Dialogs/Delete";

export default props => {
    const {messages, deleteMessage, setEditPost, user, group} = props;
    const [deletePost, setDeletePost] = useState(false);
    const messageArray = createMessagesArray(messages);
    const bottomRef = useRef();
    useEffect(
        () => {
            if(bottomRef.current){
                bottomRef.current.scrollIntoView({ behavior: "smooth" });
                // window.scrollTo(0,bottomRef.current.scrollHeight);
            }
        },
        [messages]
    );
    return (
        <div className={"posts"} >
            {
                // Dialogs
            }
            <DeleteDialog
                open={!!deletePost}
                fail={() => setDeletePost(false)}
                success={async () => {
                    try {
                        const result = await deleteMessage(deletePost.id, group);
                        setDeletePost(false);
                    } catch (e) {
                        console.error(e.message);
                    }
                }}
                item={deletePost && <Post preview item={deletePost} user={user}/>}
            />
            {
                // Rendered Items
                messageArray.map(
                    (item, index) => (
                        <Post
                            key={item.id}
                            item={item}
                            user={user}
                            setDelete={(item) => setDeletePost(item)}
                            setEdit={item => setEditPost(item)}
                        />
                    )
                )
            }
            <div ref={bottomRef} />
        </div>
    );
}
