import React from "react";
import {useUser} from "../hooks/auth";
import Login from "../views/login";
import Home from "../App";
import InternalLayout from "../components/Layout/Internal";

export default function RequiredAdmin({user, children, setLoading, loading, settings}){
    if(loading || !user || !user.uid){
        return(<Login settings={settings} message={"Du musst dich für dieses Event erst anmelden oder dich einloggen."} setLoading={setLoading}/>);
    }else{
        if(user.data && user.data.isAdmin){
            return children;
        }else{
            // Show Guest Area
            return(
                <InternalLayout user={user}>
                    <Home user={user} setLoading={setLoading}/>
                </InternalLayout>
            );
        }
    }

}
