import React from "react";
import dateFNS from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {createDate} from "../../modules/time";
import Button from "../Form/Button";

export default props => {
    const {timestampValue, dateValue, onChange, size, buttonStyle, ...p} = props;
    return (
        <MuiPickersUtilsProvider utils={dateFNS} locale={deLocale}>
            <TimePicker
                margin="normal"
                id="time-picker"
                value={dateValue ? dateValue : createDate(timestampValue)}
                onChange={onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                disablePast
                TextFieldComponent={
                    ({value, onClick}) => (
                        <Button
                            size={size ? size : "large"}
                            onClick={onClick}
                            color="primary"
                            round
                            variant={"outlined"}
                            style={{marginTop: 20, display: "block", ...buttonStyle}}
                        >
                            {value}
                        </Button>
                    )
                }
                {...p}
            />
        </MuiPickersUtilsProvider>
    );
};
