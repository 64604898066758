import React from "react";
import Button from "../Form/Button";
import {logoutUser} from "../../actions/user";

export default props => {
    return (
        <div className={"center-text content-box"}>
            <h2>Dies ist nicht dein Account?</h2>
            <p>
                Bitte logge dich aus und melde dich mit deinem Namen neu an!
            </p>
            <Button
                round
                variant={"outlined"}
                onClick={async () => {
                    try{
                        await logoutUser();
                    }catch (e) {
                        console.error(e);
                    }
                }}
                standardWidth
            >
                Logout
            </Button>
        </div>
    )
}
