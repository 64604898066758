import React from "react";
import "./item.css";
import WeddingIcon from "../../Icons/WeddingIcon";

export default function TimelineItem(props){
    const {item, index} = props;
    return(
        <>
            <div className={"timeline-icon"}>
                <WeddingIcon white name={item.icon} size={40}/>
            </div>
            <div className={`timeline-details ${index % 2 ? "" : "even"}`}>
                <h3>{item.title}</h3>
                <div style={{marginTop: 20, marginBottom: 20}}>{item.text}</div>
            </div>
        </>
    );
}
