import React from "react";
import classNames from "classnames";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from "@material-ui/core/Tooltip";

export default props => {
    const {active, name, onClick, setDelete, setEdit, onlyGuests, isOwner} = props;
    const classes = classNames({
        ["group-item"]: true,
        active
    });
    return(
        <Tooltip title={"Dies ist eine private Gruppe für alle Gäste. Das Brautpaar kann sie nicht sehen!"} disableHoverListener={!onlyGuests} arrow>
            <div className={classes} onClick={onClick}>
                {
                    onlyGuests && (
                        <LockIcon />
                    )
                }
                {name}
                {
                    isOwner && (
                        <>
                            <EditIcon
                                onClick={setEdit}
                                style={{marginLeft: 10}}
                            />
                            <DeleteIcon
                                onClick={setDelete}
                                style={{marginLeft: 5}}
                            />
                        </>
                    )
                }
            </div>
        </Tooltip>
    );
}
