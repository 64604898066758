import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

export default function Counter({ from, to, go }) {
    const nodeRef = useRef();

    useEffect(() => {
        if(go){
            const node = nodeRef.current;

            const controls = animate(from, to, {
                duration: 2,
                onUpdate(value) {
                    node.textContent = value.toFixed(0);
                },
                ease: "easeOut"
            });

            return () => controls.stop();
        }
    }, [from, to, go]);

    return <span ref={nodeRef} />;
}
