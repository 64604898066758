import React from "react";
import "./item.css";

export default function PersonIntroItem(props){
    const {item} = props;
    return(
        <div className={"person-item"}>
            <div className={"person-image"}>
                <img
                    src={item.image && item.image.url}
                />
            </div>
            <div className={"person-details"}>
                <h3>{item.name}</h3>
                <span className={"tagline"}>{item.tagLine}</span>
                <div style={{marginTop: 20, marginBottom: 20}}>{item.text}</div>
                {
                    item.email && (<div className={"person-details-item"}><strong>Email:</strong> <a href={"mailto:" + item.email}>{item.email}</a></div>)
                }
                {
                    item.telephone && (<div className={"person-details-item"}><strong>Telefon:</strong> <a href={"tel:" + item.telephone} target={"_blank"}>{item.telephone}</a></div>)
                }
            </div>
        </div>
    );
}
