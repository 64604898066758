import React, {useEffect, useState} from 'react';
import Button from '../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "./Transition";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import {uploadImage} from "../../actions/pages";
import ImageIcon from '@material-ui/icons/Image';
import ProgressBar from "../Loading/ProgressBar";
import Cropper from "../ImageEditor/Cropper";

export default function CropProfileImage(props) {
    const {open, callback, setLoading, fileName, close} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState(0);
    const [error, setError] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    useEffect(
        () => {
            if(!open){
                setCroppedImage(null);
                setImageSrc(null);
                setStep(0);
            }
        },
        [open]
    );
    useEffect(
        () => {
            if(croppedImage){
                setStep(2);
                const uploadImageFn = async (img) => {
                    const result = await uploadImage(img, fileName, setProgress);
                    callback(result);
                };
                uploadImageFn(croppedImage);
            }
        },
        [croppedImage]
    );
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/jpeg, image/png',
        maxSize: 10000000,
        multiple: false,
        noDrag: false,
        noDragEventsBubbling: true,
        onDropRejected: (files, event) => {
            setError("Beim Hochladen ist etwas schief gelaufen. Überprüfe ob die Dateien den Richtlinien entsprechen.");
        },
        onDrop: async acceptedFiles => {
            setImageSrc(acceptedFiles[0]);
            setStep(1);
        }
    });
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullScreen={fullScreen}
        >
            <DialogTitle id="alert-dialog-slide-title">Foto hinzufügen</DialogTitle>
            <DialogContent>
                {
                    error && (
                        <span className={"error-text"}>
                            {error}
                        </span>
                    )
                }
                {
                    step === 0 ? (
                        <div {...getRootProps({className: "image-dropzone"})}>
                            <input {...getInputProps()} />
                            <div />
                            <ImageIcon fontSize={"large"} color={"primary"}/>
                            <span>
                                Max. Größe: 9MB <br />
                                Unterstützte Dateitypen: .jpg, .jpeg, .png
                            </span>
                        </div>
                    ) : step === 1 ? (
                        <Cropper
                            image={imageSrc}
                            setLoading={setLoading}
                            callback={img => setCroppedImage(img)}
                        />
                    ) : (
                        <ProgressBar progress={progress} style={{marginBottom: 17}}/>
                    )
                }

            </DialogContent>
        </Dialog>
    );
}
