import {DEFAULT_SETTINGS} from "../constants";
import SignupPage from "../components/Signup/SignupPage";
import ImageComponent from "../components/Pages/Image";
import React from "react";
import LocationsPage from "../components/Pages/Locations";
import CountdownPage from "../components/Pages/Countdown";
import TimelinePage from "../components/Pages/Timeline";
import PersonIntroComponent from "../components/Pages/PersonIntro";
import CreateIcon from '@material-ui/icons/Create';
import ImageIcon from '@material-ui/icons/Image';
import PeopleIcon from '@material-ui/icons/People';
import RoomIcon from '@material-ui/icons/Room';
import AlarmIcon from '@material-ui/icons/Alarm';
import EventIcon from '@material-ui/icons/Event';
import HotelIcon from '@material-ui/icons/Hotel';
import PersonIcon from '@material-ui/icons/Person';
import RoomsPage from "../components/Pages/Rooms";

export const createPagesArray = (pages = [], loading = {}, settings = DEFAULT_SETTINGS) => {
    return pages.map(
        (item, index) => {
            switch (item.type) {
                case "signup":
                    return (<SignupPage key={item.id} index={index} setLoading={loading.setLoading} settings={settings}/>);
                case "image":
                    return (<ImageComponent key={item.id} index={index} item={item} />);
                case "locations":
                    return (<LocationsPage key={item.id} index={index} item={item} />);
                case "countdown":
                    return(<CountdownPage key={item.id} index={index} item={item} />);
                case "timeline":
                    return(<TimelinePage key={item.id} index={index} item={item} />);
                case "rooms":
                    return (<RoomsPage key={item.id} index={index} item={item} />);
                case "personIntro":
                    return(<PersonIntroComponent key={item.id} index={index} item={item} />);
                case "html":
                default:
                    return (<div key={index} className={"center-text content-box"} dangerouslySetInnerHTML={{__html: item.html}} />);
            }
        }
    );
};

export const PAGE_TYPES = {
    public: [
        {
            value: "html",
            label: "Inhalt",
            icon: <CreateIcon />
        },
        {
            value: "image",
            label: "Bild",
            icon: <ImageIcon />
        },
        {
            value: "signup",
            label: "Registrierung",
            icon: <PeopleIcon />
        },
        {
            value: "locations",
            label: "Locations",
            icon: <RoomIcon />
        },
        {
            value: "rooms",
            label: "Unterkünfte",
            icon: <HotelIcon />
        },
        {
            value: "personIntro",
            label: "Personenvorstellung",
            icon: <PersonIcon />
        },
        {
            value: "countdown",
            label: "Countdown",
            icon: <AlarmIcon />
        },
        {
            value: "timeline",
            label: "Timeline",
            icon: <EventIcon />
        }
    ],
    internal: [
        {
            value: "html",
            label: "Inhalt",
            icon: <CreateIcon />
        },
        {
            value: "image",
            label: "Bild",
            icon: <ImageIcon />
        },
        {
            value: "locations",
            label: "Locations",
            icon: <RoomIcon />
        },
        {
            value: "rooms",
            label: "Unterkünfte",
            icon: <HotelIcon />
        },
        {
            value: "personIntro",
            label: "Personenvorstellung",
            icon: <PersonIcon />
        },
        {
            value: "countdown",
            label: "Countdown",
            icon: <AlarmIcon />
        },
        {
            value: "timeline",
            label: "Timeline",
            icon: <EventIcon />
        }
    ]
};
