export function loadImage(src) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            resolve(img);
        };
        img.onerror = reject;
        img.src = src;
    });

}
export const toBase64 = file => new Promise((resolve, reject) => {
    try{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }catch (e) {
        reject(e);
    }

});
