import React, {useState, useCallback, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from "../Form/Button";
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from './canvasUtils';
import "./cropper.css";
import {toBase64} from "../../modules/image";

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
};

const CropperComponent = ({
    aspect = 1 ,
    setLoading = () => null,
    callback = () => null,
    image
}) => {
    const [imageSrc, setImageSrc] = React.useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            setLoading(true);
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            );
            setLoading(false);
            callback(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation]);

    useEffect(
        () => {
            if(image){
                console.log(image);
                const prepareImage = async (img) => {
                    let imageDataUrl = await toBase64(img);

                    // apply rotation if needed
                    const orientation = await getOrientation(img);
                    const rotation = ORIENTATION_TO_ANGLE[orientation];
                    if (rotation) {
                        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
                    }
                    setImageSrc(imageDataUrl);
                };
                setLoading(true);
                prepareImage(image).then(
                    () => {
                        setLoading(false);
                    }
                );
            }
        },
        [image]
    );

    return (
        <div>
            <React.Fragment>
                <div className={"crop-container"}>
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <div className={"crop-controls"}>
                    <div className={"crop-slider"}>
                        <label>
                            Zoom
                        </label>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            // classes={{ root: ""}}
                            onChange={(e, zoom) => setZoom(zoom)}
                        />
                    </div>
                    <div className={"crop-slider"}>
                        <label>
                            Rotation
                        </label>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            // classes={{ root: classes.slider }}
                            onChange={(e, rotation) => setRotation(rotation)}
                        />
                    </div>
                </div>
                <div className={"crop-button"}>
                    <Button
                        onClick={showCroppedImage}
                        variant="contained"
                        color="primary"
                    >
                        Hochladen
                    </Button>
                </div>
            </React.Fragment>
        </div>
    )
};

export default CropperComponent;


