import React from 'react';
import Button from '../Form/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from "./Transition";

export default function DeleteDialog(props) {
    const {open, success, fail, item} = props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={fail}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"xs"}
        >
            <DialogTitle id="alert-dialog-slide-title">Element löschen</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Bist du sicher dass du das folgende Element wirklich löschen möchtest?
                </DialogContentText>
                {item}
            </DialogContent>
            <DialogActions>
                <Button onClick={fail} color="info">
                    Abbrechen
                </Button>
                <Button onClick={success} color="error">
                    Löschen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
